<template>
    <div class="scd__objects" :class="{hidden: !show}">
        <div class="objects__title" v-if="finalAsteroidsList.length" :class="{open: !show}" @click="toggleObjectsPanel()">
            {{ $t.databaseObjects }}
        </div>
        <div class="objects__title-vertical" :class="{open: !show}" @click="!show ? toggleObjectsPanel() : null">
            {{ $t.objects }}
        </div>
        <div class="objects__close" :class="{open: !show}" @click="toggleObjectsPanel()"></div>
        <ul class="objects__list" v-if="finalAsteroidsList.length">
            <li class="objects__list--item" v-for="(object, index) of finalAsteroidsList" :key="index" :class="{active: activeObjectName === object.name}" @click="setActiveObjectName(object.name)">
                <img class="asteroid-image" src="/static/images/asteroid_default.png" :alt="`(${object.number}) ${object.name}`" />
                <span class="asteroid-description">
                    <span class="asteroid-name">{{ displayName(object) }}</span>
                    <span class="asteroid-type">{{ displayObjectType(object) }}</span>
                </span>
                <span v-if="objectLoading(object.name)" class="loading-wrapper">                    
                    <span class="loading"></span>                    
                </span>   
                <span v-if="objectLoading(object.name) && objectExceedPathAngle(object.name)" class="warning-text">The computation may take some time</span>                 
            </li>
        </ul>
        <template v-if="syntheticObjectsList.length">
            <div class="objects__title" :class="{'objects__title--expanded': finalAsteroidsList.length}">
                {{ $t.userDefinedObjects }}
            </div>
            <ul class="objects__list">
                <li class="objects__list--item" v-for="(object, index) of syntheticObjectsList" :key="index" :class="{active: activeObjectName === object.designator}" @click="setActiveObjectName(object.designator)">
                    <img class="asteroid-image" src="/static/images/asteroid_default.png" :alt="`${object.objectName}`" />
                    <span class="asteroid-description">
                        <span class="asteroid-name">{{ object.objectName }}</span>
                        <span class="asteroid-type">{{ $t.userDefinedAsteroid }}</span>
                    </span>
                    <span v-if="objectLoading(object.designator)" class="loading-wrapper">                    
                        <span class="loading"></span>                    
                    </span>   
                    <span v-if="objectLoading(object.designator) && objectExceedPathAngle(object.designator)" class="warning-text">The computation may take some time</span>                 
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
import SkychartService from '@/services/skychart.service'
import ObjectsService from '@/services/objects.service'
import SyntheticObjectsService from '@/services/synthetic-objects.service';

export default {
    name: 'SkyChartObjectsTable',
    computed: {
        show() {
            return SkychartService.getObjectsShow();
        },
        finalAsteroidsList() {
            const finalAsteroidsList = ObjectsService.getFinalAsteroidsList();
            let neaObjects = [];

            for (const index in finalAsteroidsList){
                if (finalAsteroidsList[index].filename !== "non-NEA"){
                    neaObjects.push(finalAsteroidsList[index]);
                }
            }
            return neaObjects;
        },
        syntheticObjectsList() {
            return SyntheticObjectsService.getSyntheticDefaultObjectsList();
        },
        activeObjectName() {
            return SkychartService.getActiveObjectName();
        },
        loadedObjects() {
            return SkychartService.getLoadedObjects();
        },        
        hide() {
            return SkychartService.getObjectsDetailsHide();
        }
    },
    methods: {
        displayName(object) {
            return ObjectsService.objectNumberAndName(object);
        },
        toggleObjectsPanel() {
            SkychartService.setObjectsShow(!this.show);
        },
        setActiveObjectName(name) {
            if (name === this.activeObjectName) {
                if (!this.hide) {
                    SkychartService.setObjectsDetailsHide(true);
                    SkychartService.setActiveObjectName('');
                } else {
                    SkychartService.setObjectsDetailsHide(false);
                }
            } else {
                SkychartService.setObjectsDetailsHide(false);
                SkychartService.setActiveObjectName(name);
            }
        },
        objectLoading(name) {            
            return !this.loadedObjects.includes(name);
        },
        displayObjectType(object) {            
            return ObjectsService.displayObjectType(object);
        },
        objectExceedPathAngle(name) {            
            return SkychartService.getExceedPathAngleObjects().includes(name);                        
        }
    }
}
</script>

<style>

</style>