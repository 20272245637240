<template>
    <div class="synthetic-objects">
        <div class="synthetic-objects__header" v-if="!isFvt || (isFvt && objectsList.length === 0)">
            <p class="synthetic-objects__title" :class="{'synthetic-objects__title--hidden': isFvt}">
                {{ isFvt ? '' : $t.userDefinedObjects }}
            </p>
            <button class="synthetic-objects__action link" @click="addUserDefinedObject()">
                <i class="icon-plus"></i><span>{{ $t.addUserDefinedObject }}</span>
            </button>
        </div>
        <div class="synthetic-objects__list-wrapper">
            <ul v-if="isScd" class="synthetic-objects__list">
                <li class="synthetic-objects__item" v-for="(item, index) of objectsList" :key="index">
                    {{ displayName(item) }}
                    <div class="synthetic-objects__buttons">
                        <button
                            class="synthetic-objects__button synthetic-objects__button--edit tertiary"
                            @click="handleObjectEdit(index)"
                        >
                            {{ $t.edit }}
                        </button>
                        <button
                            class="synthetic-objects__button synthetic-objects__button--remove tertiary"
                            @click="handleObjectRemove(item)"
                        >
                            {{ $t.remove }}
                        </button>
                    </div>
                </li>
            </ul>
            <div
                v-if="(isOvt || isSovt) && objectsList.length"
                class="ovt__objects-table__content"
                :class="{ loading }"
            >
                <div class="d-table-row headers">
                    <div class="d-table-cell">{{ $t.object }}</div>
                    <div class="d-table-cell-empty"></div>
                    <div class="d-table-cell orbit-switcher label">
                        {{ $t.orbitSwitchLabel }}
                        <Tooltip position="left" :tooltipText="$keys.t.switchOrbit"
                            ><i class="icon-info"
                        /></Tooltip>
                    </div>
                    <div class="d-table-cell">{{ $t.show }}</div>
                </div>
                <div class="inner">
                    <div
                        class="d-table-row item"
                        v-for="(item, index) of objectsList"
                        :key="index"
                        :class="{ 'item-highlight': isSelectedObject(item) }"
                    >
                        <div class="d-table-cell">
                            <input
                                type="radio"
                                :id="'radio_' + item.designator"
                                :value="item.designator"
                                name="radioGroup"
                                :checked="isSelectedObject(item)"
                                @change="handleSelection(item)"
                            />
                        </div>
                        <div class="d-table-cell">
                            <span @click="handleSelection(item)">
                                {{ displayName(item) }}
                            </span>
                            <div class="synthetic-objects__buttons">
                                <button
                                    class="synthetic-objects__button synthetic-objects__button--edit tertiary"
                                    @click="handleObjectEdit(index)"
                                >
                                    {{ $t.edit }}
                                </button>
                                <button
                                    class="synthetic-objects__button synthetic-objects__button--remove tertiary"
                                    @click="handleObjectRemove(item)"
                                >
                                    {{ $t.remove }}
                                </button>
                            </div>
                        </div>
                        <div class="d-table-cell orbit-switcher field">
                            <input
                                class="toggle-switch"
                                :id="`perturbedOrbit_${item.designator}`"
                                :checked="isPerturbedOn(item)"
                                :disabled="item.keplerianOnly"
                                type="checkbox"
                                @click="orbitSwitchOnChange(item, $event.target.checked, $event)"
                            />
                            <label
                                class="toggle-switch__button"
                                :class="{ disabled: item.keplerianOnly }"
                                :for="`perturbedOrbit_${item.designator}`"
                            ></label>
                        </div>
                        <div class="d-table-cell input">
                            <input
                                type="checkbox"
                                :id="item.name"
                                :disabled="!isObjectCalculated(item)"
                                :checked="isCheckedObject(item)"
                                @change="checkObject(item)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isFvt && objectsList.length">
                <p class="synthetic-objects__single-item" :class="{'synthetic-objects__single-item--active': isSelectedSyntheticFvtObject}" v-for="(item, index) of objectsList" :key="index">
                    {{ displayName(item) }}
                        <button
                            class="synthetic-objects__button synthetic-objects__button--edit tertiary"
                            @click="handleObjectEdit(index)"
                        >
                            {{ $t.edit }}
                        </button>
                </p>
            </div>
        </div>
        <p v-if="!isUserDefinedObjectCustomObservatoryEnabled && isScd" class="synthetic-objects__info">
            {{ $t.noCustomObservatoryForUserDefinedObjects }}
        </p>
    </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip";
import PopupService from "@/services/popup.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import SyntheticObjectsService from "@/services/synthetic-objects.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "SyntheticObjects",
    props: {
        tool: String,
    },
    components: {
        Tooltip,
    },
    computed: {
        objectsList() {
            const isDefaultList = SyntheticObjectsService.isDefaultSyntheticObjects();
            return isDefaultList
                ? SyntheticObjectsService.getSyntheticDefaultObjectsList()
                : SyntheticObjectsService.getSyntheticFvtObjectsList();
        },

        isUserDefinedObjectCustomObservatoryEnabled() {
            return SyntheticObjectsService.isUserDefinedObjectCustomObservatoryEnabled();
        },

        isChecked() {
            return VisualisationService.getIsChecked();
        },

        selectedObjectName() {
            return VisualisationService.getSelectedObjectName();
        },

        selectedSynodicObjectName() {
            return VisualisationService.getSelectedSynodicObjectName();
        },

        visibleRealSynodicObjectsList() {
            return SynodicCalculationService.getVisibleRealSynodicObjectsList();
        },

        loading() {
            return VisualisationService.loadingObjectsEphemerides().length;
        },

        isSelectedSyntheticFvtObject() {
            return SyntheticObjectsService.checkIsSyntheticObjectFlybyCalculated();
        },

        isScd() {
            return this.tool === "scd";
        },

        isOvt() {
            return this.tool === "ovt";
        },

        isSovt() {
            return this.tool === "sovt";
        },

        isFvt() {
            return this.tool === 'fvt';
        }
    },
    methods: {
        addUserDefinedObject() {
            PopupService.show({
                component: "PopupSyntheticObjectConfiguration",
            });
        },

        handleObjectEdit(objectIndex) {
            PopupService.show({
                component: "PopupSyntheticObjectConfiguration",
                editedObjectIndex: objectIndex,
            });
        },

        handleObjectRemove(object) {
            PopupService.show({
                component: "PopupSyntheticObjectDelete",
                selectedObject: object,
            });
        },

        displayName(item) {
            return SyntheticObjectsService.getSyntheticObjectName(item);
        },

        isSelectedObject(item) {
            const { designator } = item;
            if (this.isOvt) {
                return designator === this.selectedObjectName;
            }
            if (this.isSovt) {
                return designator === this.selectedSynodicObjectName;
            }
        },

        handleSelection(item) {
            if (this.isOvt) {
                this.selectObject(item);
                this.toggleSelected(item);
                return;
            }
            if (this.isSovt) {
                const { designator } = item;
                VisualisationService.selectSynodicObject(designator);
                return;
            }
        },

        selectObject(item) {
            const designator = item.designator;
            const isPerturbed = this.isOvtPerturbedOrbit(designator) || item.perturbedOn;
            this.selectedObjectName = designator === this.selectedObjectName ? "" : designator;
            VisualisationService.selectObject(designator, isPerturbed);
        },

        toggleSelected(item) {
            this.isSelectedObject(item) ? (this.selectedObjectName = "") : (this.selectedObjectName = item.designator);
        },

        async orbitSwitchOnChange(item, isChecked, event) { /* eslint-disable-line */
            const { designator } = item;
            if (this.isOvt) {
                item.perturbedOn = isChecked;
                if (this.isCheckedObject(item)) {
                    this.checkObject(item, true);
                    return;
                }
                this.handleOvtSyntheticObjectPerturbedOrbit(designator, isChecked);
            }
            if (this.isSovt) {
                let isPerturbedAvailable = true;
                const isVisible = SynodicCalculationService.getVisibleRealSynodicObjectsList().includes(designator);
                if (isChecked && isVisible) {
                    //event.preventDefault();
                }
                if (!isChecked && isVisible) {
                    SynodicCalculationService.switchOrbitToKeplerian(designator);
                }
                if (isChecked && isVisible) {
                    isPerturbedAvailable = await SynodicCalculationService.switchOrbitToPerturbed(designator);
                }
                isVisible && SynodicCalculationService.checkIsFocusOnObject(designator);
                const perturbedOrbitList = UtilsService.deepCopy(
                    SynodicCalculationService.getPerturbedSynodicOrbitList()
                );
                if (isChecked && isPerturbedAvailable) {
                    perturbedOrbitList.push(designator);
                }
                if (!isChecked) {
                    const objectIndex = perturbedOrbitList.indexOf(designator);
                    objectIndex !== -1 && perturbedOrbitList.splice(objectIndex, 1);
                }
                SynodicCalculationService.setPerturbedSynodicOrbitList(perturbedOrbitList);
                !isPerturbedAvailable && SynodicCalculationService.showNoPerturbedInfo(item.objectName);
            }
        },

        isObjectCalculated(item) {
            if (this.isOvt) {
                return true;
            }
            if (this.isSovt) {
                const { designator } = item;
                const calculatedObjectList = SynodicCalculationService.getCalculatedRealSynodicObjectsList();
                return !!UtilsService.findItemInObjectList("designator", designator, calculatedObjectList);
            }
        },

        handleOvtSyntheticObjectPerturbedOrbit(designator, isPerturbed) {
            const perturbedObjectsList = VisualisationService.getIsPerturbed();
            const perturbedListIndex = perturbedObjectsList.indexOf(designator);
            const isOnPerturbedList = perturbedListIndex !== -1;
            if ((isPerturbed && isOnPerturbedList) || (!isPerturbed && !isOnPerturbedList)) {
                return;
            }
            if (isPerturbed && !isOnPerturbedList) {
                perturbedObjectsList.push(designator);
                return;
            }
            perturbedObjectsList.splice(perturbedListIndex, 1);
        },

        isCheckedObject(item) {
            const { designator } = item;
            if (this.isOvt) {
                return this.isChecked.includes(designator);
            }
            if (this.isSovt) {
                return this.visibleRealSynodicObjectsList.includes(designator);
            }
        },

        checkObject(item, reload = false) {
            const { designator } = item;
            if (this.isOvt) {
                VisualisationService.checkObject(designator, item.perturbedOn, reload);
                return;
            }
            if (this.isSovt) {
                SynodicCalculationService.handleObjectVisibility(designator);
                return;
            }
        },

        isOvtPerturbedOrbit(designator) {
            const perturbedObjectsList = VisualisationService.getIsPerturbed();
            return perturbedObjectsList.includes(designator);
        },

        isPerturbedOn(object) {
            const { designator } = object;
            if (this.isOvt) {
                return object.perturbedOn || this.isOvtPerturbedOrbit(designator);
            }
            if (this.isSovt) {
                return SynodicCalculationService.getPerturbedSynodicOrbitList().includes(designator);
            }
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
@import "@/styles/common/synthetic-objects.scss";
</style>
