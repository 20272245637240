import store from '@/store'
import axios from 'axios'
import CommonGetters from '@/store/common/common-getters'
import CommonActions from '@/store/common/common-actions'
import Config from '@/constants/config'
import PopupService from '@/services/popup.service'
import SpinnerService from '@/services/spinner.service'
import FilteringService from '@/services/filtering.service'
import Keys from '@/constants/keys'
import MemoryService from '@/services/memory.service'
import VisualisationService from '@/services/visualisation.service'
import UtilsService from '@/services/utils.service'

const ObjectsService = {
    findAsteroidByPhraseUrl() {
        return Config.api.restUrl + Config.api.findAsteroidByPhraseUrl;
    },

    findAsteroidByNameUrl() {
        return Config.api.restUrl + Config.api.findAsteroidByNameUrl;
    },

    initialLoadPerformed() {
        return store.getters[CommonGetters.objectsInitialLoadPerformed];
    },

    getObjects() {
        return store.getters[CommonGetters.objects];
    },

    getObjectsSelected() {
        return store.getters[CommonGetters.objectsSelected];
    },

    getObjectsFilteredSelected() {
        const objects = ObjectsService.getObjects();
        const objectsSelected = ObjectsService.getObjectsSelected();
        const objectsFilteredSelected = [];

        if (!objects || !objects.list || !objects.list.length || !objectsSelected.length) return;

        for (const objectName of objectsSelected) {
            const object = objects.list.filter(obj => obj.name === objectName);
            if (object.length) {
                objectsFilteredSelected.push(object[0]);
            }
        }

        return objectsFilteredSelected;
    },

    setObjects(objects, addSelected = false) {
        store.dispatch(CommonActions.setObjects, objects);

        if (addSelected && objects.list && objects.list.length) {
            const selected = objects.list.map(obj => obj.name);
            ObjectsService.setObjectsSelected(selected);
        } else {
            ObjectsService.setObjectsSelected([]);
        }
    },

    concatObjects(objects, addSelected = false) {
        const currentObjects = ObjectsService.getObjects();
        const mergedObjectsArray = [...currentObjects.list, ...objects.list];
        currentObjects.list = mergedObjectsArray;

        store.dispatch(CommonActions.setObjects, currentObjects);

        if (addSelected && objects.list && objects.list.length) {
            const selected = [...ObjectsService.getObjectsSelected(), ...objects.list.map(obj => obj.name)];
            ObjectsService.setObjectsSelected(selected);
        }
    },

    setObjectsInitialLoadPerformed(value) {
        store.dispatch(CommonActions.setObjectsInitialLoadPerformed, value);
    },

    setObjectsSelected(objects) {
        store.dispatch(CommonActions.setObjectsSelected, objects);
    },

    handleSelect(objectId) {
        let selected = [ ...ObjectsService.getObjectsSelected() ];
        if (!selected.includes(objectId)) {
            selected.push(objectId);
        } else {
            selected = selected.filter(id => id !== objectId);
        }
        ObjectsService.setObjectsSelected(selected);
    },

    selectAll() {
        const objects = ObjectsService.getObjects().list;
        if (!objects || !objects.length) return;

        const selected = [];
        for (const object of objects) {
            if (!object.error) selected.push(object.name);
        }

        ObjectsService.setObjectsSelected(selected);
    },

    getConfigurationPopupMode() {
        return store.getters[CommonGetters.configurationPopupMode];
    },

    setConfigurationPopupMode(value) {
        store.dispatch(CommonActions.setConfigurationPopupMode, value);
    },

    getUserDefinedObjects() {
        return store.getters[CommonGetters.userDefinedObjects];
    },

    setUserDefinedObjects(value) {
        store.dispatch(CommonActions.setUserDefinedObjects, value);
    },

    selectNone() {
        ObjectsService.setObjectsSelected([]);
    },

    getLoadingAsteroidsList() {
        return store.getters[CommonGetters.loadingAsteroidsList];
    },

    setLoadingAsteroidsList(value) {
        store.dispatch(CommonActions.setLoadingAsteroidsList, value);
    },

    getOptRoute(){
        return store.getters[CommonGetters.optRoute];
    },

    setOptRoute(value){
        return store.dispatch(CommonActions.setOptRoute, value);
    },

    getAvailableAsteroidsList() {
        return store.getters[CommonGetters.availableAsteroidsList];
    },

    setAvailableAsteroidsList(value) {
        store.dispatch(CommonActions.setAvailableAsteroidsList, value);
    },

    getFinalAsteroidsList() {
        return store.getters[CommonGetters.finalAsteroidsList];
    },

    setFinalAsteroidsList(value) {
        store.dispatch(CommonActions.setFinalAsteroidsList, value);
    },

    getInsertAsteroidsList() {
        return store.getters[CommonGetters.insertAsteroidsList];
    },

    setInsertAsteroidsList(value) {
        store.dispatch(CommonActions.setInsertAsteroidsList, value);
    },

    getAsteroidsNames() {
        return store.getters[CommonGetters.asteroidsNames];
    },

    setAsteroidsNames(value) {
        store.dispatch(CommonActions.setAsteroidsNames, value);
    },

    setSkychartEnableVisualisation(value) {
        store.dispatch(CommonActions.setSkychartEnableVisualisation, value);
    },

    updateAsteroidsList(availableAsteroidsList = null, finalAsteroidsList = null) {
        const availableAsteroids = availableAsteroidsList || ObjectsService.getAvailableAsteroidsList();
        const finalAsteroids = finalAsteroidsList || ObjectsService.getFinalAsteroidsList();
        ObjectsService.setAvailableAsteroidsList(availableAsteroids);
        ObjectsService.setFinalAsteroidsList(finalAsteroids);
        const asteroidsNames = [];
        for (const item of finalAsteroids) {
            asteroidsNames.push(item.name);
        }
        ObjectsService.setSkychartEnableVisualisation(asteroidsNames.length > 0);
        ObjectsService.setAsteroidsNames(asteroidsNames.join());
    },

    objectConfigurationAdd(availableSelected) {
        let finalAsteroidsList = [...ObjectsService.getFinalAsteroidsList(), ...availableSelected];
        let availableAsteroidsList = [...ObjectsService.getAvailableAsteroidsList()];
        for (const item of availableSelected) {
            availableAsteroidsList = availableAsteroidsList.filter(e => e.name !== item.name);
        }
        ObjectsService.updateAsteroidsList(availableAsteroidsList, finalAsteroidsList);
        return [];
    },

    objectConfigurationAddAll() {
        const finalAsteroidsList = [...ObjectsService.getFinalAsteroidsList()];
        for (const item of ObjectsService.getAvailableAsteroidsList()) {
            finalAsteroidsList.push(item);
        }
        ObjectsService.updateAsteroidsList([], finalAsteroidsList);
        return [];
    },

    objectConfigurationRemove(finalSelected) {
        let finalAsteroidsList = [...ObjectsService.getFinalAsteroidsList()];
        const finalSelectedFilteredList = [...this.filterAvailableAsteroids(finalSelected)];
        let availableAsteroidsList = [...ObjectsService.getAvailableAsteroidsList(), ...finalSelectedFilteredList];
        for (const item of finalSelected) {
            finalAsteroidsList = finalAsteroidsList.filter(e => e.name !== item.name);
        }        
        ObjectsService.updateAsteroidsList(availableAsteroidsList, finalAsteroidsList);        
        return [];
    },

    objectConfigurationRemoveAll() {
        const availableAsteroidsList = [...ObjectsService.getAvailableAsteroidsList()];
        const finalSelectedFilteredList = [...this.filterAvailableAsteroids(ObjectsService.getFinalAsteroidsList())];
        for (const item of finalSelectedFilteredList) {
            availableAsteroidsList.push(item);
        }        
        ObjectsService.updateAsteroidsList(availableAsteroidsList, []);
        return [];
    },

    extractObjectsNames(objectsList) {
        const asteroidsNames = [];
        for (const item of objectsList) {
            asteroidsNames.push(item.name);
        }
        return asteroidsNames.join();
    },
   
    findAsteroidByPhraseThroughURL(phrase = '', autoAddObject = false, tool = 'ovt') {
        if (!phrase || phrase === '') {
            ObjectsService.setAvailableAsteroidsList([]);
            return;
        }
        ObjectsService.setLoadingAsteroidsList(true);
        const url = ObjectsService.findAsteroidByPhraseUrl() + '?query=' + encodeURIComponent(phrase);
        axios(url)
            .then(response => {
                const finalAsteroidsList = ObjectsService.getFinalAsteroidsList();
                const availableAsteroidsList = [];
                for (const item of response.data) {
                    if (!finalAsteroidsList.some(e => e.name === item.name)) {
                        availableAsteroidsList.push(item)
                    }
                }

                ObjectsService.setAvailableAsteroidsList(availableAsteroidsList);

                if (autoAddObject) {
                    let objectsToAdd = [];
                    if (availableAsteroidsList.length === 1) {
                        objectsToAdd = availableAsteroidsList;
                    } else {
                        objectsToAdd = availableAsteroidsList.filter(e => e.name.toUpperCase() == phrase.toUpperCase() || e.number == phrase.toUpperCase());
                    }

                    if (!objectsToAdd.length) {
                        const alreadyLoaded = finalAsteroidsList.some(e =>
                            e.name.toUpperCase().includes(phrase.toUpperCase()) ||
                            (e.number && phrase.includes(e.number.toString()))
                        );
                        if (alreadyLoaded) {
                            console.warn(`Object "${phrase}" already loaded, skipping addition.`);
                            return;
                        }
    
                        console.log("ObjectsToAdd.length: " + objectsToAdd.length);
                        throw new Error(`Load through URL: Object "${phrase}" was not found.`);
                    }

                    ObjectsService.objectConfigurationAdd(objectsToAdd);
                    ObjectsService.setAvailableAsteroidsList([]);
                    const designator = objectsToAdd[0].number ? objectsToAdd[0].number + '' : objectsToAdd[0].name + '';
                    if (tool === 'ovt') {
                        VisualisationService.selectObject(designator);
                    } else if (tool === 'sovt') {
                        setTimeout(() => {
                            VisualisationService.setSelectedSynodicObjectName(designator);
                        })
                    }
                }
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
            .finally(() => {
                ObjectsService.setLoadingAsteroidsList(false);
            });
	},

    findAsteroidByPhrase(phrase = '', autoAddObject = false, tool = 'ovt') {
        if (!phrase || phrase === '') {
            ObjectsService.setAvailableAsteroidsList([]);
            return;
        }
        ObjectsService.setLoadingAsteroidsList(true);
        const url = ObjectsService.findAsteroidByPhraseUrl() + '?query=' + encodeURIComponent(phrase);
        axios(url)
            .then(response => {
                const finalAsteroidsList = ObjectsService.getFinalAsteroidsList();
                let availableAsteroidsList = [];
                for (const item of response.data) {
                    if (!finalAsteroidsList.some(e => e.name === item.name)) {
                        availableAsteroidsList.push(item)
                    }
                }
                // Filter by object properties
                availableAsteroidsList = [...this.filterAvailableAsteroids(availableAsteroidsList)];                                         
                ObjectsService.setAvailableAsteroidsList(availableAsteroidsList);

                if (autoAddObject) {
                    let objectsToAdd = [];
                    if (availableAsteroidsList.length === 1) {
                        objectsToAdd = availableAsteroidsList;
                    } else {
                        objectsToAdd = availableAsteroidsList.filter(e => e.name.toUpperCase() == phrase.toUpperCase() || e.number == phrase.toUpperCase());
                    }

                    if (!objectsToAdd.length) {
                        throw new Error(`Object "${phrase}" was not found.`);
                    }

                    ObjectsService.objectConfigurationAdd(objectsToAdd);
                    ObjectsService.setAvailableAsteroidsList([]);
                    const designator = objectsToAdd[0].number ? objectsToAdd[0].number + '' : objectsToAdd[0].name + '';
                    if (tool === 'ovt') {
                        VisualisationService.selectObject(designator);
                    } else if (tool === 'sovt') {
                        setTimeout(() => {
                            VisualisationService.setSelectedSynodicObjectName(designator);
                        })
                    }
                }
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
            .finally(() => {
                ObjectsService.setLoadingAsteroidsList(false);
            });
	},

    filterAvailableAsteroids(availableAsteroidsList) {
        const filteredAsteroidList = availableAsteroidsList.filter(item => {
            // code: NEO black/Non-NEO gray 
            const showCode = this.isCodeAllowed(item.code);

            // sourceType: NEOCP blue
            const showNEOCP = this.isNEOCPAllowed(item.sourceType.name);

            // bodyType.name = Asteroid(id=1)/Comet(id=2)/Unconfirmed(id=3)
            const showBodyType = this.isBodyTypeAllowed(item.bodyType.name); 

            // Return true if all conditions are met
            return showNEOCP && (showCode || item.sourceType.name === 'NEOCP') && showBodyType;
        }); 
        return filteredAsteroidList;
    },

    isCodeAllowed(code) {
        return (code === "NEO" && VisualisationService.getProperty("showNEOs")) ||
               (code === "non-NEO" && VisualisationService.getProperty("showNonNEOs"));
    },
    isNEOCPAllowed(source) {
        return source === 'NEOCP' ? VisualisationService.getProperty("showNEOCP") : true;
    },  
    isBodyTypeAllowed(bodyType) {
        return (bodyType === "Asteroid" && VisualisationService.getProperty("showAsteroids")) ||
        (bodyType === "Comet" && VisualisationService.getProperty("showComets")) ||
        (bodyType === "Unconfirmed");
    },          
    findAllAsteroidByPhrase(phrase = '') {        
        if (!phrase || phrase === '') {
            ObjectsService.setAvailableAsteroidsList([]);
            return;
        }
        ObjectsService.setLoadingAsteroidsList(true);
        const url = ObjectsService.findAsteroidByPhraseUrl() + '?query=' + encodeURIComponent(phrase);
        axios(url)
            .then(response => {
                const availableAsteroidsList = [];
                for (const item of response.data) {
                    availableAsteroidsList.push(item);
                }
                ObjectsService.setAvailableAsteroidsList(availableAsteroidsList);
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
            .finally(() => {
                ObjectsService.setLoadingAsteroidsList(false);
            });
	},

    async findAsteroidByName()  {
        const insertList = ObjectsService.getInsertAsteroidsList();
        if (!insertList || insertList === '') {
            return;
        }
        SpinnerService.show();
        const url = ObjectsService.findAsteroidByNameUrl() + '?query=' + insertList;
        await axios(url)
            .then(response => {
                const objectsList = response.data.asteroids;
                const objectsErrors = response.data.errors;
                ObjectsService.setFinalAsteroidsList(objectsList);
                ObjectsService.setAsteroidsNames(ObjectsService.extractObjectsNames(objectsList));
                if (objectsErrors && objectsErrors.length) {
                    const successed = objectsList.length;
                    const errored = objectsErrors.length;
                    const found = successed + errored;
                    const message = `${successed} ${Keys.t.of} ${found} ${Keys.t.objectsFound}, ${errored} ${Keys.t.objectsNotFound}:<br />${objectsErrors.join(', ')}`;
                    setTimeout(() => {
                        PopupService.show({
                            component: 'PopupInfo',
                            type: 'warning',
                            message,
                        });
                    }, 0);          
                }
            })
            .catch(error => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: 'PopupInfo',
                    type: 'error',
                    message
                });
            })
            .finally(() => {
                SpinnerService.hide();
            });
	},

    updateObjectsFilteringValues(objects, overrideObjects) {
        if (!objects || !objects.length) return;
        const objectsRecent = ObjectsService.getObjects();
        const objectsList = objectsRecent.list;

        if (overrideObjects) {
            objectsRecent.total = objects.length;
            objectsRecent.list = objects;
            ObjectsService.setObjects(objectsRecent, true);
            ObjectsService.setUserDefinedObjects(true);
            const finalAsteroidsList = ObjectsService.getFinalAsteroidsList();
            const newFinalAsteroidList = [];
            for (const asteroid of finalAsteroidsList) {
                if (objectsRecent.list.some(e => e.name === asteroid.name)) {
                    newFinalAsteroidList.push(asteroid);
                }
            }
            ObjectsService.setFinalAsteroidsList(newFinalAsteroidList);
            MemoryService.saveSetupMemory();
        } else {
            for (const object of objects) {
                if (!object.error) {
                    const index = objectsList.findIndex(obj => obj.name === object.name);
                    objectsList[index].filters = object.filters;
                }
            }
        }

        FilteringService.setLastCalculatedEphemerides(true);
    },

    getDesignatorAndName(name) {
        const object = ObjectsService.getObjects().list.filter(e => e.name === name)[0];
        let designatorAndName = name;
        if (object.number) {    
            designatorAndName = `(${object.number}) ${object.name}`;
        }
        return designatorAndName;
    },

    objectNumberAndName(item) {
        const itemNumber = item.number || item.designator ? `(${item.number || item.designator}) ` : '';
        const itemName = item.name;
        return itemNumber + itemName;
    },

    displayObjectType(object) {
        if (ObjectsService.isCometWithoutObjectGroup(object)) {
            return object.bodyType.name;
        } else {
            if (object.objectGroup.name === 'N/A') { 
                return Keys.t.nonNeo + ' ' + object.bodyType.name;
            } else {
                return object.objectGroup.name + ' ' + object.bodyType.name;
            }
        }
    },

    isCometWithoutObjectGroup(object) {
        return (object.bodyType.name === 'Comet' || object.bodyType.name === 'JPL') && object.objectGroup.name === 'N/A';
    },
    isComet(object) {
        return (object.bodyType.name === 'Comet');
    },
}

export default ObjectsService;
