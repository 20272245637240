export default {
    titles: {
        opt: 'Observation Planning Tool',
        scd: 'Sky Chart Display Tool',
        fvt: 'Flyby Visualisation Tool',
        ovt: 'Orbit Visualisation Tool',
        sovt: 'Synodic Orbit Visualisation Tool',
        esa: 'European Space Agency',
        neo: 'NEO Toolkit',
        neocc: 'Near-Earth Objects Coordination Centre',
        ESAGaia: 'ESA/Gaia/DPAC;',
        CreativeCommons: 'CC BY-SA 3.0 IGO. ',
        JD_UTC: 'Date',
        AST_RA: 'Right Ascension',
        AST_DEC: 'Declination',
        AST_HA: 'Hour Angle',
        AST_AZ: 'Azimuth',
        AST_ALT: 'Elevation',
        AST_ECLONG: 'Ecliptic Longitude',
        AST_ECLAT: 'Ecliptic Latitude',
        AST_GLONG: 'Galactic Longitude',
        AST_GLAT: 'Galactic Latitude',
        MAG: 'Magnitude',
        ERR_A: 'Positional Uncertainty (semi-major axis)',
        ERR_B: 'Positional Uncertainty (semi-minor axis)',
        PA: 'Uncertainty Orientation',
        DELTA: 'Δ Distance from the Earth',
        R: 'R Distance from the Sun',
        ELON: 'Sun Elongation',
        SUN_ALT: 'Sun Altitude',
        PHASE: 'Phase Angle',
        APPMOT_RA: 'Apparent Motion in Right Ascension',
        APPMOT_DEC: 'Apparent Motion in Declination',
        SKY_MOV: 'Sky Movement',
        POS_ANG: 'Position Angle',
        ELSUN: 'Sun Elevation',
        AIRM: 'Airmass',
        ELEV: 'Elevation',
        MOOEL: 'Moon Elongation',
        MOON_ALT: 'Moon Elevation',
        MOONGLOW: 'Moonglow',
        DEN_STARS: 'Density of Stars',
        DAYS_UNOBSERVED: 'Num. days unobserved',
        ARC: 'Length observ. arc',
    },
    routes: {
        opt: '/opt',
        setup: 'setup',
        optFiltering: 'filtering',
        optResults: 'results',
        optLongTerm: 'longterm',
        optShortTerm: 'shortterm',
        optEphemerides: 'ephemerides',
        scd: '/scd',
        scdVisualisation: 'visualisation',
        fvt: '/fvt',
        ovt: '/ovt',
        sovt: '/sovt',
    },
    urls: {
        esa: 'https://esa.int',
        neo: 'http://neo-tst.ssa.esa.int/neo-tool-support',
        neocc: 'https://neo.ssa.esa.int/',
        termsAndConditions: 'https://www.esa.int/Services/Terms_and_conditions',
        privacyPolicy: 'https://neo.ssa.esa.int/privacy-policy',
        neoToolkit: 'https://neo.ssa.esa.int/neo-toolkit/',
        ESAGaia: 'https://www.esa.int/ESA_Multimedia/Images/2020/12/The_colour_of_the_sky_from_Gaia_s_Early_Data_Release_32',
        CreativeCommons: 'https://creativecommons.org/licenses/by-sa/3.0/igo/deed.en',
    },
    tooltips: {
        database: '',
        bodyType: '',
        orbitType: '',
        risk: 'This object is currently in the Risk List of ESA\'s Near-Earth Objects Coordination Centre.',
        pha: 'Potentially hazardous asteroid',
        magnitude: 'V magnitude of the object',
        elevation: 'Altitude/elevation of the object above the horizon',
        sunElongation: 'Sun-object angular distance',
        skyMovement: 'Speed of the object in the sky plane',
        skyUncertainty: 'Angular distance in which it is expected to find the object',
        galaxyLatitude: 'Latitude above and below the Galactic plane',
        nrOfDaysUnobserved: 'Time since the last recorded observation',
        observationArc: 'Time period between earliest and latest observations',
        moonElongation: 'Moon-object angular distance',
        densityOfStars: 'Stars per square arcminute in the sky region around the object',
        moonglow: 'Sky V magnitude change due to the presence of the Moon',
        impact: 'Object impacted with Earth on',
        closeApproach: 'Close approach to Earth computed for',
        JD_UTC: '',
        AST_RA: 'Right Ascension of the object',
        AST_DEC: 'Declination of the object',
        AST_HA: 'Hour Angle of the object',
        AST_AZ: 'Azimuth of the object',
        AST_ALT: 'Elevation',
        AST_ECLONG: 'Ecliptic longitude of the object',
        AST_ECLAT: 'Ecliptic latitude of the object',
        AST_GLONG: 'Galactic longitude of the object',
        AST_GLAT: 'Galactic latitude of the object',
        MAG: 'V magnitude of the object',
        ERR_A: 'Semi-major axis of uncertainty ellipse',
        ERR_B: 'Semi-minor axis of uncertainty ellipse',
        PA: 'Positional angle of the major axis of the 1-sigma error ellipse',
        DELTA: 'Distance from the Earth',
        R: 'Distance from the Sun',
        ELON: 'Sun-object angular distance',
        SUN_ALT: 'Elevation of the Sun with respect to the horizon',
        PHASE: 'Angle defined between the Sun and Earth relative to an object',
        APPMOT_RA: 'Apparent motion of the object in Right Ascension',
        APPMOT_DEC: 'Apparent motion of the object in Declination',
        SKY_MOV: 'Speed of the object in the sky plane',
        POS_ANG: 'Angle of the object relative to the North Celestial Pole towards East',
        ELSUN: 'Sun elevation',
        AIRM: 'Amount of air crossed according to the object\'s position, secant of the zenith angle',
        ELEV: 'Altitude of the object above the horizon',
        MOOEL: 'Moon-object angular distance',
        MOON_ALT: 'Elevation of the Moon with respect to the horizon.',
        MOONGLOW: 'Sky V magnitude change due to the pressence of the Moon',
        DEN_STARS: 'Stars per square arcminute in the sky region around the object',
        DAYS_UNOBSERVED: 'Time since the last recorded observation',
        ARC: 'Time period between earliest and latest observations',
        shorttermTimelineUTC: 'The horizontal axis of the plot shows the time in UTC. By setting the mouse over the time blocks of the plot, both UTC and local time references for each object are shown.',
        'ESA/PDO': 'ESA\'s Planetary Defence Office database',
        NEOCP: 'Near-Earth Object Confirmation Page',
        JPL: 'JPL Small-Body Database Near-Earth Comets',
        sunlightExplanation: 'Outside the Astronomical twilight time frame, affected by sunlight',
        objectAltitudeExplanation: 'Elevation of the object above the horizon',
        onlyObservableTimestampsExplanation: 'Displays only the timestamps that fulfil the filtering criteria in Filtering tab',
        positionAffectedByStarlight: 'Position affected by starlight',
        sexagesimalCoordinates: 'Sexagesimal coordinates',
        closeApproachesSelection: `Please follow the next steps to search for objects that will have a close approach with Earth within 3 lunar distances (or the Sphere Of Influence of other planets) from the main body:</br>
        a. Define the search time frame by selecting the start and end dates.</br>
        b. Select the main body for the close approach.</br>
        c. Optional: you can concentrate the search by selecting the object or objects for which you are searching the close approaches.</br>
        d. After clicking the "Search" button, a list of close approaches will appear in the table below.</br>
        e. Note: the Moon's average distance to Earth is known as the lunar distance and it is equivalent to 384 399 km.</br>
        In the object names list shown, NEAs are displayed in black, NEOCPs in light blue, and non-NEAs in grey.</br>`,
        closeApproachesFound: 'Selecting an object from the results table will display the animated visualisation of the close approach. Objects that do not have the flyby data ready are disabled.',
        keplerianOrbit: 'Unperturbed orbit of the asteroid around the Sun, not affected by any other force than the solar central gravity (no effects of close approaches or impacts with Earth or any other body). The selected object\'s Keplerian orbit is highlighted in darker color.',
        perturbedOrbit: 'Orbit of the asteroid computed taking into account a fully perturbed dynamical model (effects of close approaches with Earth or any other body are also included). The selected object\'s perturbed orbit is highlighted in darker color. The orbits of impacting objects are shown until the impact date. The Earth is always shown with the Keplerian orbit.',
        astronomicalTwilight: 'The astronomical twilight markers define the duration of the "astronomical night". This is the time in which the Sun altitude is equal to or lower than -18 degrees with respect to the horizon of the observer on a given date. If the astronomical twilight markers are not displayed (and hence no astronomical night is defined), this means the Sun altitude is never lower than -18 deg on the selected date and from the selected observatory position. In these cases, the Sunlight shading is shown over all timeline blocks to reflect that the astronomical night is never reached on such date and the observations might be affected by sunlight at any time.',
        neoccRiskListObjects: 'The Risk List is a catalogue of all objects for which a non-zero impact probability has been computed.',
        sovtInputData: 'Orbital elements of the user-defined object shall be provided as Keplerian elements centred in the Sun and projected in the Ecliptic of 2000.0 coordinate system (using perihelion and aphelion distances instead of semi-major axis and eccentricity).',
        limitingVisualMagnitude: 'The limiting visual magnitude is used to compute the so-called detection polar for a given object and represents the observational capability of a telescope on Earth. The detection polar is computed by making use of the H-G magnitude model proposed by Lumme and Bowell (1981). The polar has two solutions, one close to the Earth and another one close to the Sun, which will not be generally observable.',
        objectOrbitRange: 'This orbit range is only displayed for the Keplerian orbits of the objects. The inner and outer orbit ranges have a spherical shape. However, the only sections shown are around the Ecliptic plane, graphically approximated as flat surfaces. As a consequence, remarkably inclined objects may show orbits crossing the inner ranges and remain well within limits or even distant from the outer ranges.',
        earthName: 'Earth, Sun and the rest of the planets are off scale for an easier graphical interpretation and a more comfortable visibility of the system.',
        earthOnScale: 'If active, only the Earth is in scale. The size of the NEOs is reduced for a better visualisation.'
    },
    t: {
        setup: 'Setup',
        filtering: 'Filtering',
        results: 'Results',
        selectObservatoryPoint: 'Select observatory location',
        selectTimeFrame: 'Select time frame',
        type: 'Type',
        geocentric: 'Geocentric',
        topocentric: 'Topocentric',
        findObservatory: 'Find observatory',
        latitude: 'Latitude',
        longitude: 'Longitude',
        useMyCurrentLocation: 'Use my current location',
        currentDate: 'Current date (UTC)',
        startDate: 'Start date (UTC)',
        endDate: 'End date (UTC)',
        goTo: 'Go to',
        startTyping: 'Start typing...',
        custom: 'CUSTOM',
        geolocationNotSupported: 'Geolocation is not supported by your browser.<br />Please allow access to the geolocation service in your browser\'s privacy settings and try again.',
        selectedTimeFrameExplanation: 'Selecting any date range within a 30-day period from today enables object filtering with pre-computed data (faster ephemerides calculation). Selecting a date range outside of this 30-day period (including days in the past) will not use pre-computed data for the filtering stage. However, object selection by type and database will be still available.',
        selectedTimeFrameIsOutOfShortTermAnalysis: 'Warning: the selected time frame is out of the 30-day period of pre-computed data. The calculations may take slightly longer.',
        configureObjects: 'Configure objects',
        totalFound: 'Total found',
        totalSelected: 'Selected',
        resetAll: 'Reset all filters',
        clearAllFilters: 'Clear all filters',
        timestep: 'Time step',
        calculateEphemerides: 'Calculate ephemerides',
        invalidObservatoryData: 'Observatory data not valid or missing.<br />Please check the selected coordinates and try again.',
        invalidTimeFrameData: 'Time frame not valid or missing.<br />Please check the selected time frame and try again.',
        select: 'Select',
        show: 'Show',
        object: 'Object',
        previousObservation: 'Previous observations',
        database: 'Database',
        bodyType: 'Body type',
        orbitType: 'Orbit type',
        risk: 'Risk list',
        pha: 'PHA',
        absoluteMagnitude: 'Absolute magnitude',
        visualMagnitude: 'Visual magnitude',
        angularDistToSun: 'Sun Elongation',
        moonElong: 'Moon Elongation',
        galacticLatitude: 'Galactic Latitude (absolute value)',
        apparentVelocity: 'Sky Movement',
        starDensity: 'Stars Density',
        skyUncertainty: 'Sky Uncertainty',
        elevation: 'Elevation',
        moonglow: 'Moonglow',
        nrOfDaysUnobserved: 'Number of Days Unobserved',
        lengthObservArc: 'Observation Arc Length',
        in: 'in',
        mag: 'mag',
        degrees: 'deg',
        sliderDegrees: 'Vertical FoV dimension',
        squareDeg: 'deg²',
        arcsecmin: 'arcsec/min',
        arcsec: 'arcsec',
        days: 'days',
        starsarcmin2: 'stars/arcmin^2',
        magarcsec2: 'mag/arcsec^2',
        au: 'au',
        fov: 'FOV',
        timeUnit_m: 'Minute',
        timeUnit_h: 'Hour',
        timeUnit_d: 'Day',
        cancel: 'Cancel',
        submit: 'Submit',
        ok: 'OK',
        value: 'Value',
        yes: 'Yes',
        no: 'No',
        nA: 'N/A',
        chooseUnits: 'Choose units',
        minTimestep1: 'Minimum time step is',
        minTimestep2: 'Input value has been increased to its minimum value for unit',
        maxTimestep1: 'Maximum time step is',
        maxTimestep2: 'Input value has been decreased to its maximum value for unit',
        confirmReset: 'Are you sure you want to reset to default all filtering settings?',
        confirmClearAllFilters: 'Are you sure you want to clear all filter settings?',
        isCommingSoon: 'is comming soon!',
        removeFilter: 'Remove filter',
        selectAll: 'Select all',
        unselectAll: 'Unselect all',
        min: 'min',
        max: 'max',
        impactCloseApproach: 'Impact / Close approach',
        date: 'Date UTC',
        save: 'Save',
        selectObjectsFromList: 'Select objects from list',
        insertList: 'Insert list',
        objectsColorInList: 'NEAs are displayed in black, non-NEAs in grey, and NEOCP objects in light blue (NEOCP objects cannot be displayed in the OVT, FBVT and SOVT).',
        add: 'Add',
        addAll: 'Add all',
        remove: 'Remove',
        removeAll: 'Remove all',
        finalList: 'Final list',
        objectsSelected: 'objects selected',
        pleaseProvideObjectDesignatorsOrObjectNames: 'Please provide object designators or object names. Names are case-sensitive.',
        commaSeparatedValuesOrNextLineValues: 'Comma-separated values or next line values',
        of: 'of',
        objectsFound: 'objects found',
        objectsNotFound: 'objects not found',
        seeAll: 'See all',
        thereAreMoreObjectsFulfillingThisCriteria: 'There are more objects fulfilling these criteria. Scroll down to load more.',
        thereAreNoObjectsFulfillingThisCriteria: 'There are no objects fulfilling these criteria.',
        none: 'None',
        setupConfigurationWasChanged: 'Setup configuration was changed. It will influence the results of the filtering.<br />Do you want to recalculate filtering results for the new configuration?',
        restorePreviousConfiguration: 'Restore previous configuration',
        refreshFiltering: 'Refresh filtering',
        filteringConfigurationWasChanged: 'Setup configuration was changed. It will influence the results<br />Do you want to recalculate ephemerides for the new configuration?',
        goToResults: 'Go to results',
        recalculateEphemerides: 'Recalculate ephemerides',
        objects: 'Objects',
        selectObjects: 'Select objects',
        objectsConfigurationExplanation: 'If you know the names or designators of the objects which you want to observe, you can select them with the second option. If you do not know which objects you want to observe, you can select the first option and use the filtering to find them.',
        objectsConfigurationExplanationForSCD: 'Select the names or designators of the objects that you want to visualise. Note that in the object names list shown, NEAs are displayed in black, NEOCPs in light blue and non-NEAs in grey.',
        findUsingFitlering: 'Find objects using the filtering',
        configureMyList: 'Configure my list of objects',
        configureYourOwnListOfObjects: 'Edit my list of objects',
        yourDataHasBeenSuccessfullySaved: 'Your data has been successfully saved!',
        nowYouCanUseItToRestore: 'Now you can use it to restore the current tool state or to use selected objects in other tools from NEO Toolkit.',
        pleaseNoteThatYourData: 'Please note that your data will be kept in our database for 30 days.',
        linkToShare: 'Link to share',
        doYouWantToLoadPreviouslySavedData: 'Do you want to load previously saved data?<br />It will overwrite your current tool settings.',
        load: 'Load',
        invalidToolStateId: 'Saved tool data is invalid or missing.<br />Please check if the URL is correct and try again.',
        dataLoadedSuccessfully: 'Data loaded successfully!',
        calculationsPerformedSuccessfully: 'Calculations performed successfully! You may now discover the Results tab.',
        yourInputDataHasExceededMaximumDataPointsLimit: 'Your input data has exceeded maximum data points limit.',
        maximumDataPionts: 'Maximum data points',
        currentDataPoints: 'Current data points',
        pleaseAdjustYourCalculationInputsBeforeProceeding: 'Please adjust your calculation inputs before proceeding.',
        longTermPlots: 'Long-term plots',
        shortTermPlots: 'Short-term plots',
        ephemerides: 'Ephemerides',
        onlyObservableTimestamps: 'Only observable timestamps',
        ephemeridesConfiguration: 'Ephemerides configuration',
        default: 'Default',
        all: 'All',
        userDefined: 'User defined',
        download: 'Download',
        more: 'More',
        listOfEphemeridesColumns: 'List of ephemerides columns',
        possibleQuantities: 'Possible quantities',
        selectedQuantities: 'Selected quantities',
        designatorName: '(DESIGNATOR) NAME',
        astronomicalTwilight: 'Astronomical twilight',
        sunshine: 'Sunshine',
        objectAltitude: 'Object altitude',
        observatoryAltitude: 'Observatory altitude',
        calculationInProgress: 'Calculation in progress',
        processedObjects: 'objects have been processed',
        estimatedTimeToFinish: 'estimated time to finish:',
        objectsQueue: 'All objects are in the queue to be processed',
        remainingTime: 'Remaining time',
        approximately: 'approximately',
        second: 'second',
        seconds: 'seconds',
        itTakesLongerThanExpected: 'it is taking longer than expected',
        doYouWantToStopCalculation: 'Do you want to stop the process of calculating the ephemerides?',
        stop: 'Stop',
        calculationWasCanceled: 'Calculation was canceled successfully.',
        altitude: 'Altitude',
        azimuth: 'Azimuth',
        objectObservableUnderSetConditions: 'Object observable under set conditions',
        objectNotObservableUnderSetConditions: 'Object not observable under set conditions',
        visualiseOnChart: 'Visualise on chart',
        constant: 'Constant',
        thereIsNoSavedResults: 'There is no saved results',
        astronomicalNights: 'Astronomical night in local time',
        txt: 'txt',
        pdf: 'pdf',
        utc: 'UTC',
        utcTimeSymbol: 'JD_UTC',
        local: 'local',
        retryCalculation: 'Retry calculation',
        copyLinkToClipboard: 'Copy link to clipboard',
        linkWasSuccessfullyCopiedToClipboard: 'Link was successfully copied to clipboard.',
        centreCoordinates: 'Observatory coordinates',
        observatory: 'Observatory',
        jplNecs: 'JPL NECs',
        copyright: 'Copyright',
        allRightsReserved: 'All rights reserved',
        termsAndConditions: 'Terms & Conditions',
        privacyPolicy: 'Privacy Policy',
        cookieNotificationText: 'ESA uses cookies to track visits to our website only, no personal information is collected.<br />By continuing to use the site you are agreeing to our use of cookies. <a href="https://neo.ssa.esa.int/privacy-policy" title="Privacy Policy" target="_blank">Find out more about our cookies</a>',
        iAgree: 'I agree',
        visualisation: 'Visualisation',
        setTimestep: 'Set timestep',
        setTimestepExplanation: 'Set the timestep to be used to compute the necessary calculations to present the data in the Visualisation tab.',
        details: 'Details',
        dataPointsSelection: 'Data Points Selection',
        selectTimestampToVisualise: 'Select timestamp to visualise conditions on sky chart',
        selectTimestampToVisualiseExplanation: 'Select timestamp to visualise conditions on sky chart',
        addMoreTimestepsAfterSelectedTimestamp: 'Add more timesteps after selected timestamp',
        displaySettings: 'Display settings',        
        export: 'Export',
        image: 'Image (PNG)',
        galaxies: 'Galaxies',
        nebulae: 'Nebulae',
        dsos: 'Deep sky objects',        
        constellations: 'Constellations',
        regions: 'Regions',
        milkyWayBackground: 'Milky Way',
        PDF: 'Document (PDF)',
        visualiseHorizon: 'Visualise horizon',
        invertColors: 'Invert colors',
        trackDescription: 'Track description',
        legend: 'Legend',
        uncertaintyRegion: 'Uncertainty regions',
        asteroidsSizeFactor: 'Asteroid size factor (log scale)',
        starlight: 'Starlight',
        planets: 'Planets',
        planetNames: 'Planet names',
        //trailPoint: 'Trail point',
        trailPointSymbol: 'Trail point symbol',
        triangle: 'Triangle',
        square: 'Square',
        disc: 'Disc',
        selectedValue: 'Selected value',
        minimumValue: 'Minimum value',
        maximumValue: 'Maximum value',
        sunlight: 'Sunlight',
        landscape: 'Landscape',
        atmosphere: 'Atmosphere',
        horizontalGrid: 'Horizontal grid',
        equatorialGrid: 'Equatorial grid',
        eclipticGrid: 'Ecliptic grid',
        trailPointSize: 'Trail point size',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        id: 'ID',
        number: 'Number',
        name: 'Name',
        code: 'Code',
        filename: 'File name',
        objectGroup: 'Object group',
        sourceType: 'Source type',
        timestamp: 'Timestamp UTC',
        ra: 'RA',
        dec: 'Dec',
        starlightAffected: 'Starlight affected',
        magLabel: 'Mag',
        objectTracking: 'Object tracking',
        dmsFormat: 'DMS Format',
        nonNeo: 'non-NEO',
        focusOnSelectedObject: 'Focus on selected object',
        focusOnTheCenter: 'Focus on the center',
        neocc: 'NEOCC',
        pdo: 'ESA/PDO',
        neoccRisk: 'NEOCC Risk list objects',
        objectName: 'Object name',
        neoGroups: 'NEO Groups',
        asteroidFamilies: 'Asteroid families',
        spectralClasses: 'Spectral classes',
        objectDetails: 'Object details',
        arrowLegend: 'Arrow legend',
        telescopeParameters: 'Telescope parameters',
        objectsSettings: 'Objects settings',        
        zoom: 'Zoom',
        planetsNames: 'Planets names',
        planetsOrbits: 'Planets orbits',
        earthName: 'Earth name',
        earthOrbit: 'Earth orbit',
        synodicEarthOrbit: 'Earth orbit',
        horizontalPlane: 'Horizontal plane',
        equatorialPlane: 'Equatorial plane',
        distanceToEquatorialPlane: 'Distance to Equatorial plane',
        distanceToEcliptic: 'Distance to ecliptic plane',
        perturbedOrbitPropagation:'Propagation of the perturbed orbit',
        trailDurationYears: 'Trail years (UTC)',
        leadDurationYears: 'Lead years (UTC)',
        objectsNames: 'Objects names',
        maxSize: 'Max. size',
        maxMagnitude: 'Max. magnitude',
        maxSize_units: 'm',
        diameter_units: 'm',
        maxMagnitude_units: 'mag',
        magnitude_units: 'mag',
        img: 'Image (PNG)',
        gif: 'Animation (GIF)',
        mp4: 'Movie (MP4)',
        webm: 'Movie (WEBM)',
        designator: 'Designator',
        diameter: 'Diameter',
        magnitude: 'Magnitude',
        distanceToSun: 'Distance to Sun',
        distanceToMainBodyCentre: 'Distance to {mainBody} centre',
        orbitPropagation: 'Orbit propagation',
        keplerian: 'Keplerian',
        keplerianOrbits: 'Keplerian orbit',
        perturbedOrbits: 'Perturbed orbit',
        planetOrbitLineThickness: 'Planets orbit line thickness',
        objectOrbitLineThickness: 'Objects orbit line thickness',
        sun: 'Sun',
        moon: 'Moon',
        sunName: 'Sun name',
        moonName: 'Moon name',
        asteroidOrbit: 'Asteroid orbit',
        sunOrbit: 'Sun orbit',
        moonOrbit: 'Moon orbit',
        vector: 'vector',
        perturbed: 'Perturbed',
        accurate: 'Accurate',
        orbit: 'orbit',
        orbits: 'orbits',
        orbitSwitchLabel: 'Orbit',
        orbitLegend: 'Orbit legend',
        orbitReliable: 'Orbit reliable',
        playbackSpeed: 'Playback speed',
        systemTimeMJD: 'Simulation time MJD',
        systemTime: 'Simulation time',
        closeEncounterDetected: 'Close encounter with {mainBody} detected',
        keplerianOrbitForObject: 'Keplerian orbit for object',
        connectionToFbvtIsNotEnabledForUserDefinedObjects: 'Connection to FBVT is not enabled for user-defined objects',
        perturbedOrbitForObject: 'Perturbed orbit for object',
        doesNotExist: 'does not exist',
        currentDateNoUTC: 'Current date',
        startDateNoUTC: 'Start date',
        endDateNoUTC: 'End date',
        useRightMouseButton: 'Use right mouse button to change viewer`s position',
        axisGuide: 'Axis guide',
        compass: 'Compass',
        crosshair: 'Crosshair',
        dateAndTimeUTC: 'Date and time (UTC)',
        objectNames: 'Object names',
        objectTrajectories: 'Object trajectories',
        startRecording: 'Start recording',
        stopRecording: 'Stop recording',
        stopCalculations: 'Stop calculations',
        utcTime: 'UTC time',
        localTime: 'Local time',
        searchForCloseApproaches: 'Search for close approaches',
        closeUpproachesFound: 'Close approaches found',
        mainBody: 'Main body',
        search: 'Search',
        compute: 'Compute',
        approachDate: 'Approach date',
        allMainBodies: 'All main bodies',
        findCloseApproachesFor: 'Find close approaches for',
        optional: 'optional',
        version: 'Version',
        noCloseApproachesDetected: 'There are no flybys detected within the given search parameters, or any other close approach takes place beyond 3 Lunar distances (or the Sphere Of Influence of other planets).<br />Please change the parameters and try again.',
        noCloseApproachesForComents: 'Currently, the Flyby Visualisation Tool does not provide info on close approaches for comets, NEOCPs and non-NEAs.',
        flybyAboveLimit: 'The calculated flyby is beyond the limit of 3 lunar distances (or the Sphere Of Influence of other planets) and cannot be visualized.',
        searchingForAllObjectsWillTakeAWhile: 'The search may take some time. Selecting specific objects/main bodies or shortening the time interval may help reducing it.',
        geostationaryOrbit: 'Geostationary orbit',
        closeApproachDataNotFound: 'Close approach data not found.',
        guidingArrows: 'Guiding arrows',
        lightEffect: 'Light effect',
        modelSource: 'Model source',
        flybyDataNotAvailable: 'Flyby data not available at the moment',
        impactDate: 'Impact date',
        preliminaryData: 'Preliminary data',
        preliminaryDataTooltip: 'If the user selects a time interval in the Setup section fully contained within the next 30 days from today, the data shown for each object in the table below correspond to the minimum and maximum values reached by each parameter within such time interval (or a single value if the parameter does not change in such interval).<bt /><br /><br />If the time interval is not fully contained within the next 30 days, the parameters have no pre-computed data and N/A values are displayed. However, these values are computed and provided in the Results section after the calculation of the ephemerides is executed.',
        particleSize: 'Particle size',
        uncertaintyDensity: 'Frequency of ellipsoids',
        uncertaintyDensity_units: 'of',
        uncertaintyFactor: 'Multiplication factor',
        uncertaintyFactor_units: 'times',
        closestApproachPoint: 'Closest approach point',
        closestApproachOf: 'Closest approach of',
        to: 'to',
        perturbedOrbit: 'Orbit of the asteroid computed taking into account a fully perturbed dynamical model (effects of close approaches with Earth or any other body are also included). The orbits of impacting objects are shown until the impact date.',
        switchOrbit: 'Switch on to show the perturbed orbit for the selected object/s (only applicable for NEAs; only Keplerian orbits are shown for non-NEAs). If switched off, the Keplerian orbit is shown. The Earth is always shown with the Keplerian orbit. The future and past propagation of the perturbed orbits can be configured in the Display Settings menu.',                      
        calculationsStopped: 'Calculations stopped',
        noData: 'No data',
        latitudeDefinition: 'Latitude of the telescope location, in degrees',
        longitudeDefinition: 'Longitude of the telescope location, in degrees',
        altitudeDefinition: 'Altitude of the telescope location, in metres',
        nonNEAsNotPreFiltered: 'OPT does not handle non-NEAs',
        nonNEAsTraceNotAvailable: 'SCDT does not handle non-NEAs',
        background: 'Background colour',
        referenceEpoch: 'Reference epoch',
        perihelionDistance: 'Perihelion distance',
        aphelionDistance: 'Aphelion distance',
        inclination: 'Inclination',
        rightAscension: 'Right ascension of the ascending node',
        perihelionArgument: 'Argument of the perihelion',
        meanAnomaly: 'Mean anomaly at epoch',
        slopeParameter: 'Slope parameter',
        magnitudeLimit: ' Limiting visual magnitude',
        inputData: 'Input data',
        provideTelescopeMagnitude: 'Please provide your telescope limiting magnitude with any value within -30.0 and 32.0 mag',
        calculate: 'Calculate',
        charts: 'Charts',
        focusOn: 'Focus on',
        detectionPolar: 'Detection polar',
        limitingMagnitudeLegend: 'Limiting Magnitude Legend',
        orbitRange: 'Object orbit range',
        element: 'Element',
        dark: 'Dark',
        light: 'Light',
        earth: 'Earth',
        multipleObjects: 'Object(s)',
        userInterface: 'User interface elements',
        earthDistance: 'Distance to Earth',
        time: 'Time',
        phaseAngle: 'Phase angle',
        actualDates: 'Actual dates',
        daysNumber: 'Number of days',
        userDefinedObject: 'User defined object',
        transparency: 'Transparency',
        fontSlider: 'Font',
        color: 'Colour',
        objectDataRetrived: 'The data for the selected object has been successfully retrieved from the database.',
        invisibleCharts: 'To view the charts, select the calculated object.',
        timeUnit_date: 'Date',
        missingParameters: 'Please select a different object as all the required parameters are not available for the one you have chosen.',
        errorOccured: 'An error has occurred. Please try again.',
        trajectoryImpact: 'Object {objectName} impacts with the Earth on {impactDate}.<br /><br />There is no further representation of the trajectory after impact.',
        noPerturbed: 'For the selected date range and object {objectName} there is no representation of the perturbed trajectory.',
        ephemeridesData: 'For the selected date range and object {objectName} there is no ephemerides data.',
        synodicPlanets: 'Planets',
        synodicPlanetsNames: 'Planets names',
        synodicPlanetsOrbits: 'Planets orbits',
        earthOnScale: 'Earth on scale',
        slope: 'Slope',
        orbitPeriod: 'Orbit period',
        synodicOrbitPeriod: 'Synodic orbit period',
        years: 'yr',
        solarElongation: 'Elongation angle',
        elongationCone: 'Minimum solar elongation cone',
        coneAngle: 'Cone aperture angle',
        calculationsInProgress: 'Calculations in progress. Please wait until they are completed before changing the date range.',
        databaseObjects: 'Database objects',
        userDefinedObjects: 'User-defined objects',
        userDefinedAsteroid: 'User-defined Asteroid',
        addUserDefinedObject: 'Add user-defined object',
        edit: 'Edit',
        saveObject: 'Save object',
        copyValues: 'Copy values',
        provideObjectValues: 'Provide values for your object',
        copyExistingObject: 'Copy values from existing object',
        pericentreDistance: 'Pericentre distance',
        eccentricity: 'Eccentricity',
        ascendingNodeLongitude: 'Longitude of the ascending node',
        pericentreArgument: 'Argument of the pericentre',
        pericentrePassage: 'Time of pericentre passage (calendar epoch)',
        copyInProgress: 'Copying...',
        removeUserDefinedObject: 'Are you sure you want to remove object {objectName}?',
        clearAll: 'Clear all',
        noCustomObservatoryForUserDefinedObjects: 'User-defined objects for custom observatories cannot be visualized at the moment.',
        notUniqueObjectName: 'The object name must be unique',
        limitedNumber: 'Please provide value within {min} and {max}',
        closeApproachCalculated: 'Close approach calculated successfully',
        focusOnObject: 'Focus on the object',
        errorDuringApiCall: 'An error occurred during the API call. Please try again.',
        perihelionDistanceError: 'Perihelion distance must be less than aphelion distance',
        aphelionDistanceError: 'Aphelion distance must be greater than perihelion distance',
        ephemeridesForObjectNotAvailable: 'The ephemerides for object ${designator} are not available.',
        showNEOs: 'NEOs',
        showNonNEOs: 'Non-NEOs',
        showNEOCP: 'NEOCP',        
        showAsteroids: 'Asteroids',
        showComets: 'Comets',
        showUnconfirmed: 'Unconfirmed'
    },
};
