<template>
    <table class="ovt__risklist">
      <tr>        
        <td>
          <input class="toggle-switch" id="ovt-risklist" type="checkbox" :checked="riskListChecked" @change="checkRiskList()" />        
          <label class="toggle-switch__button" for="ovt-risklist"></label>  
          <span class="label">
            {{ $t.neoccRisk }}
            <div v-if= "riskListChecked" class="color-input-wrapper">
                <input class="color" type="color" id="ovt-risklist-color" name="ovt-risklist-color" :value="changeColors()" @input="updateColor($event.target.value)">

            </div> 
          </span>          
        </td>	
        <td>
          <Tooltip position="bottom" v-if="$keys.tooltips.neoccRiskListObjects" :tooltipText="$keys.tooltips.neoccRiskListObjects">
            <i class="icon-info" />
          </Tooltip>
        </td>
      </tr>
    </table>
  </template>
  

                   

<script>
import VisualisationService from '@/services/visualisation.service'
import SpacekitService from '@/services/spacekit.service'
import UtilsService from '@/services/utils.service'
import Tooltip from '@/components/common/Tooltip'


export default {
    name: 'OrbitRiskList',
    data() {
        return {
            color: UtilsService.randomHexColor(false, true)
        }
    },
    computed: {
        riskListChecked() {
            return VisualisationService.getRiskListChecked();
        },
    },
    watch: {
        riskListChecked(newVal) {
            const selectType = 'risk';
            const phrase = 'risk';
            if (newVal) {
                VisualisationService.selectOption(phrase,selectType,this.color.replace('#', ''));
                VisualisationService.getSmallobjectsData(selectType, phrase).then(smallObjects => SpacekitService.addSmallObjects(selectType, phrase, smallObjects, this.color.replace('#', '')));
            } else {
                SpacekitService.removeSmallObjects(selectType, phrase);
                VisualisationService.deselectOption(phrase,selectType);
            }
        }
    },
    methods: {
        checkRiskList() {
            VisualisationService.setRiskListChecked(!this.riskListChecked);
        },
        updateColor(color) {
            const groupObjects = SpacekitService.getSmallObjectsGroup('risk_list');

            const inv = (hex) => '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('');


            if (VisualisationService.getInvertColors()){
                color = inv(color);
                this.color = color;
            }

            if (groupObjects) {  
                SpacekitService.updateGroupColor(groupObjects, color);
            }
        },
        changeColors(){
            const hex = this.color; 
            const inv = (hex) => '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('')
            if (VisualisationService.getInvertColors()){
                return inv(hex);
            }else{
                return hex;
            }
        },    
    },
    components: {
        Tooltip
    },
    mounted() {   
        const orbitRiskSelects = VisualisationService.getOrbitVisualisationActiveSelects();
        if (orbitRiskSelects && orbitRiskSelects['risk'] && orbitRiskSelects['risk'][0])
            for (let i = 0; i < orbitRiskSelects['risk'].length; i++){
                if (orbitRiskSelects['risk'][i].option === 'risk')
                    this.color ='#' + orbitRiskSelects['risk'][i].color;
            }
    }
}
</script>

<style>

</style>
