<template>
    <div v-for="option of displaySettingsOptions" :key="option" class="field"
        :class="{ 'field--multiple': typeOf(option) === 'multiple' }">          
        <template v-if="typeOf(option) == 'checkbox' && isCheckboxVisible(option)">
            <template v-if="hasTooltip(option) && isChecked(option)">
                 <Tooltip position="top-center" :tooltipText="tooltip(option)">                   
                    <input class="toggle-switch" :id="option" type="checkbox" :checked="isChecked(option)" :disabled="isDisabled(option)" @change="onChange(option, 'value', $event.target.checked)" />
                    <label class="toggle-switch__button" :for="option"></label>                                                        
                    <span class="label">
                        <span @click="isDisabled(option) ? null : onChange(option, 'value', !isChecked(option))">{{ displayName(option) }}</span>                                                        
                        <div v-if= "isChecked(option) && hasColor(option)" class="color-input-wrapper">
                            <input class="color" type="color" :id="option + '_color'" :name="option + '_color'" :value="color(option)" @input="onChange(option, 'color', $event.target.value)">
                        </div>
                        <span v-if="hasUnits(option)" class="units" v-html="displayGridUnits()"></span>                  
                    </span>                     
                </Tooltip> 
            </template>
            <template v-else>
                <input class="toggle-switch" :id="option" type="checkbox" :checked="isChecked(option)"
                :disabled="option !== 'orbitRange' ? isDisabled(option) : isOrbitRangeDisabled" @change="onChange(option, 'value', $event.target.checked)" />
            <label class="toggle-switch__button" :for="option" :class="{'disabled': option === 'orbitRange' && isOrbitRangeDisabled}"></label>
            <span class="label" :class="{'with-tooltip': isTooltip(option)}">
                <span @click="isDisabled(option) ? null : onChange(option, 'value', !isChecked(option))">{{ displayName(option) }}</span>
                <Tooltip v-if="isTooltip(option)" :class="{'settings__tooltip--left': isOrbitRangeOptionTooltip(option), 'settings__tooltip--earth-on-scale': isEarthOnScaleTooltip(option)}" position="bottom" :tooltipText="setTooltipText(option)"><i class="icon-info" /></Tooltip>
                <div v-if="isChecked(option) && hasColor(option)" class="color-input-wrapper">
                    <input class="color" type="color" :id="option + '_color'" :name="option + '_color'"
                        :value="color(option)" @input="onChange(option, 'color', $event.target.value)">
                    </div>
                    <span v-if="hasUnits(option)" class="units" v-html="displayGridUnits()"></span>
                </span>                        
            </template>
        </template>                                                         
        <template v-if="typeOf(option) == 'number'">
            <div v-if="show(option)">
                <div v-if="hasLabel(option)">
                    <label :for="option">{{ option }}:</label>
                </div>
                <div class="input-wrapper">
                    <input type="number" :value="value(option)" :id="option" :name="option"
                        @change="onChange(option, 'value', $event.target.value)" :min="min(option)" :max="max(option)" />
                </div>
            </div>
        </template>
        <template v-if="typeOf(option) == 'slider'">
            <div v-if="show(option) && isSynodicPlanetsNamesSizeSliderVisible(option)">
                <PanelSlider :option="option" :settings="this.settings[this.type][option]" :onChange="onChange"
                    :min="min(option)" :max="max(option)" />
            </div>
        </template>
        <template v-if="typeOf(option) == 'range'">
            <div v-if="show(option)">
                <label :for="type + '_' + option">{{ displayName(option) }}:</label>
                <div class="input-wrapper">
                    <input type="number" :step="step(option)" :min="min(option)" :max="max(option) - 1"
                        :value="value(option)[0]" :id="type + '_' + option" :name="type"
                        @change="onChange(option, 'value', [$event.target.value, value(option)[1]])" />
                    <span class="separator">-</span>
                    <input type="number" :step="step(option)" :min="min(option) + 1" :max="max(option)"
                        :value="value(option)[1]" :id="type + '_' + option" :name="type"
                        @change="onChange(option, 'value', [value(option)[0], $event.target.value])" />
                    <span class="units">{{ units(option) }}</span>
                </div>
            </div>
        </template>
        <template v-if="typeOf(option) == 'date'">
            <div v-if="show(option)">
                <PanelDatePicker :option="option" :settings="this.settings[this.type][option]" :onChange="onChange"
                    :min="min(option)" :max="max(option)" />
            </div>
        </template>
        <template
            v-if="(typeOf(option) == 'label' && option !== 'background') || (typeOf(option) == 'label' && this.settings[this.type]['milkyWayBackground'].value == false && option == 'background')">
            <div class="field">
                <span class="label">
                    {{ $t[option] }}:
                    <div v-if="hasColor(option)" class="color-input-wrapper">
                        <input class="color" type="color" :id="option + '_color'" :name="option + '_color'"
                            :value="color(option)" @input="onChange(option, 'color', $event.target.value)">
                    </div>
                </span>
            </div>
        </template>
        <template v-if="typeOf(option) === 'multiple'">
            <div class="option-multiple__container" :class="{ hidden: !show(option) }">
                <div class="option-multiple__wrapper" v-if="parentName(option) === 'detectionPolar' || parentName(option) === 'limitingMagnitudeLegend'">
                    <template v-for="(child, key) in settings[type][option].children" :key="key">
                        <div class="option-multiple__slider" v-if="child.type === 'slider'">
                            <PanelSlider :option="key" :settings="child" :onChange="onChildChange" :min="child.min"
                                :max="child.max" :decimalFormat="child.decimalFormat" :groupName="option" />
                        </div>
                        <div class="option-multiple__colorpicker" v-if="child.type === 'colorpicker'">
                            <label class="option-multiple__colorpicker-label" :for="key">{{ $t[key] }}:</label>
                            <input class="option-multiple__colorpicker-input" type="color" :id="key" :name="key"
                                v-model="child.value" @input="onChildChange(key, option, $event.target.value)">
                        </div>
                    </template>
                </div>
                <div class="option-multiple__wrapper option-multiple__wrapper--column" v-if="parentName(option) === 'elongationCone'">
                    <template v-for="(child, key) in settings[type][option].children">
                        <div class="option-multiple__slider" v-if="child.baseline" :key="key">
                            <PanelSlider :option="key" :settings="child" :onChange="onChildChange" :min="child.min"
                                :max="child.max" :decimalFormat="child.decimalFormat" :groupName="option" />
                        </div>
                    </template>
                    <div class="option-multiple__wrapper">
                        <template v-for="(child, key) in settings[type][option].children">
                            <div class="option-multiple__slider" v-if="child.type === 'slider' && !child.baseline" :key="key">
                                <PanelSlider :option="key" :settings="child" :onChange="onChildChange" :min="child.min"
                                    :max="child.max" :decimalFormat="child.decimalFormat" :groupName="option" />
                            </div>
                            <div class="option-multiple__colorpicker" v-if="child.type === 'colorpicker' && !child.baseline" :key="key">
                                <label class="option-multiple__colorpicker-label" :for="key">{{ $t[key] }}:</label>
                                <input class="option-multiple__colorpicker-input" type="color" :id="key" :name="key"
                                    v-model="child.value" @input="onChildChange(key, option, $event.target.value)">
                            </div>           
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'
import PanelSlider from '@/components/common/PanelSlider'
import PanelDatePicker from '@/components/common/PanelDatePicker'
import UtilsService from '@/services/utils.service'
import Tooltip from '@/components/common/Tooltip'
import SynodicCalculationService from '@/services/synodic-calculation.service'

export default {
    name: 'VisualisationObjectsSettings',
    props: {
        type: String,
        settings: Object,
    },
    components: {
        PanelSlider,
        PanelDatePicker,
        Tooltip
    },
    emits: [
        'change'
    ],
    computed: {
        displaySettingsOptions() {
            return VisualisationService.getSettingsOptions(this.type);
        },
        tool() {
            return VisualisationService.getTool();
        },
        closeApproach() {
            return VisualisationService.getSelectedCloseApproach();
        },
        isOrbitRangeDisabled() {
            const selectedSynodicObjectName = VisualisationService.getSelectedSynodicObjectName();
            if (!selectedSynodicObjectName) {
                return false;
            }
            const synodicPerturbedOrbitList = SynodicCalculationService.getPerturbedSynodicOrbitList();
            if (!synodicPerturbedOrbitList.length) {
                return false;
            }
            return synodicPerturbedOrbitList.includes(selectedSynodicObjectName);
        }
    },
    watch: {
        closeApproach: {
            deep: true,
            handler(newVal) {
                if (newVal && newVal.jds && newVal.jds.length) {
                    // Launch event                                                
                    this.$emit('change', {
                        group: 'uncertaintyDensity',
                        property: 'value',
                        value: 240
                    });
                }
            }
        }
    },
    methods: {
        now() {
            //return new Date();    
            return VisualisationService.getCurrentDate();
        },
        originCalculations() {
            const originDateCalculations = VisualisationService.getSettings().originDateCalculations;
            const minDate = new Date(originDateCalculations);
            return minDate;
        },
        maxCalculations() {
            const maxYearsCalculations = VisualisationService.getSettings().maxYearsCalculations;
            let maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() + maxYearsCalculations);
            return maxDate;
        },
        numberOfJds(option) {
            return this.closeApproach ? this.closeApproach.jds.length : this.settings[this.type][option].value;
        },
        numberOfMaxJds() {
            return this.closeApproach ? this.closeApproach.jds.length : 1;
        },
        onChildChange(option, groupName, value) {
            VisualisationService.updateChildSetting({ type: this.type, groupName, optionName: option, optionValue: value });
        },
        displayName(option) {
            if (option === 'horizontalGrid') {
                return this.tool === 'fvt' ? this.$t['equatorialGrid'] : this.$t['eclipticGrid'];
            } else if (option === 'horizontalPlane') {
                return this.tool === 'fvt' ? this.$t['distanceToEquatorialPlane'] : this.$t['distanceToEcliptic'];
            } else if (option === 'magnitude') {
                return this.$t.absoluteMagnitude;
            } else {
                return this.$t[option];
            }
        },
        displayGridUnits() {
            if (this.tool === 'ovt' || this.tool === 'sovt') {
                return `<br />1 au`;
            } else if (this.tool === 'fvt') {
                let gridDensity = '100.000';
                const mainBodyId = !this.closeApproach || !this.closeApproach.mainBody ? null : this.closeApproach.mainBody.id;
                if (mainBodyId) {
                    const mainBodyType = VisualisationService.getMainBodyParameters(mainBodyId).type;
                    if (mainBodyType === 'rocky') {
                        gridDensity = '50.000';
                    }
                }
                return `<br />${gridDensity} km`;
            } else {
                return '';
            }
        },
        isDisabled(option) {
            return (this.settings[this.type][option].disabled === undefined) ? false : this.settings[this.type][option].disabled;
        },
        typeOf(option) {
            return this.settings[this.type][option].type;
        },
        parentName(option) {
            return this.settings[this.type][option].parent
        },
        isChecked(option) {
            return (this.typeOf(option) != 'checkbox') || this.settings[this.type][option].value;
        },
        hasColor(option) {
            return this.settings[this.type][option].color;
        },
        hasLabel(option) {
            return (this.settings[this.type][option].label === undefined) ? true : this.settings[this.type][option].label;
        },
        hasParent(option) {
            return this.settings[this.type][option].parent;
        },
        hasUnits(option) {
            return this.settings[this.type][option].units;
        },
        color(option) {
            const hex = this.settings[this.type][option].color;
            const inv = (hex) => '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('')
            if (VisualisationService.getInvertColors()) {
                return inv(hex);
            } else {
                return hex;
            }
        },
        value(option) {
            return this.settings[this.type][option].value;
        },
        values(option) {
            return this.settings[this.type][option].values;
        },
        min(option) {
            let minValue = this.settings[this.type][option].min;
            // the value is set with a function                        
            if (typeof minValue === 'string') {
                minValue = this[minValue]();
            }
            return minValue;
        },
        max(option) {
            let maxValue = this.settings[this.type][option].max;
            // the value is set with a function                        
            if (typeof maxValue === 'string') {
                maxValue = this[maxValue]();
            }
            return maxValue;
        },
        step(option) {
            return this.settings[this.type][option].step;
        },
        units(option) {
            return this.settings[this.type][option].units;
        },
        parent(option) {
            return this.settings[this.type][option].parent;
        },
        show(option) {
            return !this.hasParent(option) ||
                (this.hasParent(option) && (this.isChecked(this.parent(option)) || this.typeOf(this.parent(option)) != 'checkbox'));
        },
        hasTooltip(option){                
            return this.tool === 'sovt' ? null : this.settings[this.type][option].tooltip;
        },
        tooltip(option) {            
            return this.settings[this.type][option].tooltip;
        },
        onChange(option, property, value) {
            if (option === 'orbitRange' && this.isOrbitRangeDisabled) {
                return;
            }          
            if (Array.isArray(value)) { // range                           
                const [valueMin, valueMax] = value.map(v => UtilsService.clamp(v, this.min(option), this.max(option)));
                value = [valueMin, valueMax];
            } else if (this.typeOf(option) === 'date') {
                // nothing
            }
            else if (property === 'color') {
                const color = value;

                const inv = (hex) => '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('');

                if (VisualisationService.getInvertColors()) {
                    value = inv(color);
                }
            }
            else { 
                value = UtilsService.clamp(value,this.min(option), this.max(option));
            }

            // Launch event                
            VisualisationService.updateSetting({
                group: this.type,
                change: {
                    option: option,
                    property: property,
                    value: value,
                }
            });
        },
        isTooltip(option) {
            const optionProperties = this.settings[this.type][option];
            if (!optionProperties.tooltipInfo) {
                return false;
            }
            const {tools, key} = optionProperties.tooltipInfo;
            return tools.includes(this.tool) && key;
        },
        setTooltipText(option) {
            const {tooltipInfo: {key}} = this.settings[this.type][option];
            return this.$keys.tooltips[key];
        },
        isOrbitRangeOptionTooltip(option) {
            const {tooltipInfo: {key}} = this.settings[this.type][option];
            return key === 'objectOrbitRange';
        },
        isEarthOnScaleTooltip(option) {
            const {tooltipInfo: {key}} = this.settings[this.type][option];
            return key === 'earthOnScale';
        },

        isCheckboxVisible(option) {
            if (option !== 'synodicPlanetsNames' && option !== 'synodicPlanetsOrbits') {
                return true;
            }
            const isParent = this.hasParent(option);
            if (!isParent) {
                return true;
            }
            return this.isChecked(this.parent(option));
        },

        isSynodicPlanetsNamesSizeSliderVisible(option) {
            const isSynodicPlanetsNamesSizeSlider = option === 'synodicPlanetsNamesSize';
            if (!isSynodicPlanetsNamesSizeSlider) {
                return true;
            }
            const isSynodicPlanetsEnabled = this.isChecked('synodicPlanets');
            return isSynodicPlanetsEnabled;
        }
    },
    mounted() {
        if (this.closeApproach && this.closeApproach.jds && this.closeApproach.jds.length && !this.settings[this.type]['uncertaintyDensity']) {
            // Launch event                                                
            this.$emit('change', {
                group: 'uncertaintyDensity',
                property: 'value',
                value: this.closeApproach.jds.length
            });
        }
    }
}
</script>

<style></style>
