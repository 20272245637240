<template>
    <div class="vt__simulation" :class="{ 'with-config': configPanelOpen, inverted: invertColors && !onAir }">
        <span v-if="loadingObjects.length" class="loading-wrapper">
            <span class="loading"></span>
        </span>
        <slot />
    </div>
</template>

<script>
import SpacekitService from "@/services/spacekit.service";
import VisualisationService from "@/services/visualisation.service";
import HUDService from "@/services/hud.service";
import MemoryService from "@/services/memory.service";
import * as THREE from "three";
import UtilsService from "@/services/utils.service";

export default {
    name: "VisualisationSimulation",
    props: {
        tool: String,
    },
    computed: {
        selects() {
            return VisualisationService.getOrbitVisualisationSelects();
        },
        activeSelects() {
            return VisualisationService.getOrbitVisualisationActiveSelects();
        },
        loadingObjects() {
            return VisualisationService.loadingObjectsEphemerides();
        },
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },
        invertColors() {
            return VisualisationService.getInvertColors();
        },
        selectedCloseApproach() {
            return VisualisationService.getSelectedCloseApproach();
        },
        onAir() {
            return VisualisationService.getOrbitRecorderOnAir();
        },
        settings() {
            return VisualisationService.getSettings();
        },
        horizontalPlane() {
            return this.settings && this.settings.objectsSettings.horizontalPlane.value;
        },
        uncertaintyRegion() {
            return this.settings && this.settings.objectsSettings.uncertaintyRegion.value;
        },
        uncertaintyRegionColor() {
            return this.settings && this.settings.objectsSettings.uncertaintyRegion.color;
        },
        uncertaintyDensity() {
            return this.settings && this.settings.objectsSettings.uncertaintyDensity.value;
        },
        asteroidsSizeFactor() {
            return this.settings && this.settings.objectsSettings.asteroidsSizeFactor.value;
        },
        uncertaintyFactor() {
            return this.settings && this.settings.objectsSettings.uncertaintyFactor.value;
        },
        perturbedOrbitPropagation() {
            return this.settings && this.settings.objectsSettings.perturbedOrbitPropagation.value;
        },
        trailDurationYears() {
            return this.settings && this.settings.objectsSettings.trailDurationYears.value;
        },
        leadDurationYears() {
            return this.settings && this.settings.objectsSettings.leadDurationYears.value;
        },
        geostationaryOrbit() {
            return this.settings && this.settings.objectsSettings.geostationaryOrbit.value;
        },
        horizontalGrid() {
            return this.settings && this.settings.objectsSettings.horizontalGrid.value;
        },
        axisGuide() {
            return this.settings && this.settings.objectsSettings.axisGuide.value;
        },
        objectNames() {
            return this.settings && this.settings.objectsSettings.objectNames.value;
        },
        objectNamesColor() {
            return this.settings && this.settings.objectsSettings.objectNames.color;
        },
        objectNamesSize() {
            return this.settings && this.settings.objectsSettings.objectNamesSize.value;
        },
        magnitudeLabelsColor() {
            return this.settings && this.settings.objectsSettings.magnitudeLabelsProperties.children.color.value;
        },
        magnitudeLabelsSize() {
            return this.settings && this.settings.objectsSettings.magnitudeLabelsProperties.children.fontSlider.value;
        },
        closestApproachPoint() {
            return this.settings && this.settings.objectsSettings.closestApproachPoint.value;
        },
        diameter() {
            return this.settings && this.settings.objectsSettings.diameter.value;
        },
        magnitude() {
            return this.settings && this.settings.objectsSettings.magnitude.value;
        },
        maxSize() {
            return this.settings && this.settings.objectsSettings.maxSize;
        },
        maxMagnitude() {
            return this.settings && this.settings.objectsSettings.maxMagnitude;
        },
        milkyWayBackground() {
            return this.settings && this.settings.objectsSettings.milkyWayBackground.value;
        },
        starsBackground() {
            return this.settings && this.settings.objectsSettings.dsos.value;
        },
        sun() {
            return this.settings && this.settings.objectsSettings.sun.value;
        },
        moon() {
            return this.settings && this.settings.objectsSettings.moon.value;
        },
        sunOrbit() {
            return this.settings && this.settings.objectsSettings.sunOrbit.value;
        },
        moonOrbit() {
            return this.settings && this.settings.objectsSettings.moonOrbit.value;
        },
        asteroidOrbit() {
            return this.settings && this.settings.objectsSettings.asteroidOrbit.value;
        },
        sunOrbitColor() {
            return this.settings && this.settings.objectsSettings.sunOrbit.color;
        },
        moonOrbitColor() {
            return this.settings && this.settings.objectsSettings.moonOrbit.color;
        },
        asteroidOrbitColor() {
            return this.settings && this.settings.objectsSettings.asteroidOrbit.color;
        },
        planetsNames() {
            return this.settings && this.settings.objectsSettings.planetsNames.value;
        },
        planetsNamesColor() {
            return this.settings && this.settings.objectsSettings.planetsNames.color;
        },
        planetsNamesSize() {
            return this.settings && this.settings.objectsSettings.planetsNamesSize.value;
        },
        planetsOrbits() {
            return this.settings && this.settings.objectsSettings.planetsOrbits.value;
        },
        planetsOrbitsColor() {
            return this.settings && this.settings.objectsSettings.planetsOrbits.color;
        },
        earthName() {
            return this.settings && this.settings.objectsSettings.earthName.value;
        },
        earthNameColor() {
            return this.settings && this.settings.objectsSettings.earthName.color;
        },
        earthNameSize() {
            return this.settings && this.settings.objectsSettings.earthNameSize.value;
        },
        earthOrbit() {
            return this.settings && this.settings.objectsSettings.earthOrbit.value;
        },
        earthOrbitColor() {
            return this.settings && this.settings.objectsSettings.earthOrbit.color;
        },
        synodicEarthOrbit() {
            return this.settings && this.settings.objectsSettings.synodicEarthOrbit.value;
        },
        synodicEarthOrbitColor() {
            return this.settings && this.settings.objectsSettings.synodicEarthOrbit.color;
        },
        lightEffect() {
            return this.settings && this.settings.objectsSettings.lightEffect.value;
        },
        guidingArrows() {
            return this.settings && this.settings.objectsSettings.guidingArrows.value;
        },
        keplerianOrbitsColor() {
            return this.settings && this.settings.objectsSettings.keplerianOrbits.color;
        },
        perturbedOrbitsColor() {
            return this.settings && this.settings.objectsSettings.perturbedOrbits.color;
        },
        planetOrbitLineThicknessValue(){
            return this.settings && this.settings.objectsSettings.planetOrbitLineThickness.value;
        },
        objectOrbitLineThicknessValue(){
            return this.settings && this.settings.objectsSettings.objectOrbitLineThickness.value;
        },
        backgroundColor() {
            return this.settings && this.settings.objectsSettings.background.color;
        },
        keepPlanetTexture() {
            return VisualisationService.getInvertColors();
        },
        orbitRange() {
            return this.settings && this.settings.objectsSettings.orbitRange.value;
        },
        detectionPolar() {
            return this.settings && this.settings.objectsSettings.detectionPolar.value;
        },
        detectionPolarTransparency() {
            return this.settings && this.settings.objectsSettings.detectionPolarProperties.children.transparency.value;
        },
        detectionPolarColor() {
            return this.settings && this.settings.objectsSettings.detectionPolarProperties.children.color.value;
        },
        limitingMagnitudeLegend() {
            return this.settings && this.settings.objectsSettings.limitingMagnitudeLegend.value;
        },
        limitingMagnitudeLegendSize() {
            return this.settings && this.settings.objectsSettings.magnitudeLabelsProperties.children.fontSlider.value;
        },
        limitingMagnitudeLegendColor() {
            return this.settings && this.settings.objectsSettings.magnitudeLabelsProperties.children.color.value;
        },
        isSovtTool() {
            return this.tool === "sovt";
        },
    },
    watch: {
        selects: {
            deep: true,
            handler() {
                const { type, phrase, color } = VisualisationService.getSelectedOptionValue();
                if (type && phrase && color) {
                    const selectType = type;
                    VisualisationService.getSmallobjectsData(selectType, phrase).then(smallObjects => SpacekitService.addSmallObjects(selectType, phrase, smallObjects, color));
                }
            },
        },
        configPanelOpen() {
            this.onResize();
        },
        horizontalPlane(newVal) {
            SpacekitService.toggleHorizontalPlane(newVal);
        },
        uncertaintyRegion(newVal) {
            SpacekitService.toggleUncertaintyRegion(newVal);
        },
        uncertaintyRegionColor(newVal) {
            SpacekitService.updateUncertaintyColor(newVal);
        },
        uncertaintyDensity(newVal) {
            SpacekitService.updateUncertaintyDensity(newVal);
        },
        asteroidsSizeFactor(newVal) {
            SpacekitService.updateAsteroidsSizeFactor(newVal);
        },
        uncertaintyFactor(newVal) {
            SpacekitService.updateUncertaintyFactor(newVal);
        },
        geostationaryOrbit(newVal) {
            SpacekitService.toggleGeostationaryOrbit(newVal);
        },
        horizontalGrid(newVal) {
            SpacekitService.toggleHorizontalGrid(newVal);
        },
        axisGuide(newVal) {
            SpacekitService.toggleAxisGuide(newVal);
        },
        objectNames(newVal) {
            SpacekitService.toggleObjectsNames(newVal);
        },
        objectNamesColor(newVal) {
            SpacekitService.updateNamesColor("objects", newVal);
        },
        objectNamesSize(newVal) {
            SpacekitService.updateNamesSize("objects", newVal);
        },
        magnitudeLabelsColor(newVal) {
            SpacekitService.updateNamesColor('magnitudeLabels', newVal);
        },
        magnitudeLabelsSize(newVal) {
            SpacekitService.updateNamesSize('magnitudeLabels', newVal);
        },
        closestApproachPoint(newVal) {
            SpacekitService.toggleClosestApproachPoint(newVal);
        },
        onAir(newVal) {
            HUDService.toggleWatermarks(newVal);
        },
        diameter: {
            deep: true,
            handler() {
                SpacekitService.updateDisplayLimiters();
            },
        },
        magnitude: {
            deep: true,
            handler() {
                SpacekitService.updateDisplayLimiters();
            },
        },
        maxSize() {
            SpacekitService.updateDisplayLimiters();
        },
        maxMagnitude() {
            SpacekitService.updateDisplayLimiters();
        },
        milkyWayBackground(newVal) {
            SpacekitService.toggleMilkyWay(newVal);
        },
        starsBackground(newVal) {
            SpacekitService.toggleStars(newVal);
        },
        sun(newVal) {
            SpacekitService.toggleSpaceObject("sun", newVal);
        },
        moon(newVal) {
            SpacekitService.toggleSpaceObject("moon", newVal);
        },
        sunOrbit(newVal) {
            SpacekitService.toggleOrbit("sun", newVal);
        },
        moonOrbit(newVal) {
            SpacekitService.toggleOrbit("moon", newVal);
        },
        asteroidOrbit(newVal) {
            SpacekitService.toggleOrbit("asteroid", newVal);
        },
        sunOrbitColor(newVal) {
            SpacekitService.updateOrbitColor("sun", newVal);
        },
        moonOrbitColor(newVal) {
            SpacekitService.updateOrbitColor("moon", newVal);
        },
        asteroidOrbitColor(newVal) {
            SpacekitService.updateOrbitColor("asteroid", newVal);
        },
        planetsNames(newVal) {
            SpacekitService.togglePlanets("name", newVal);
        },
        planetsNamesColor(newVal) {
            SpacekitService.updateNamesColor("planets", newVal);
        },
        planetsNamesSize(newVal) {
            SpacekitService.updateNamesSize("planets", newVal);
        },
        planetsOrbits(newVal) {
            SpacekitService.togglePlanets("orbit", newVal);
        },
        planetsOrbitsColor(newVal) {
            SpacekitService.updatePlanetsOrbitsColor(newVal);
        },
        earthName(newVal) {
            SpacekitService.togglePlanets("name", newVal, true);
        },
        earthNameColor(newVal) {
            SpacekitService.updateNamesColor("earth", newVal);
        },
        earthNameSize(newVal) {
            SpacekitService.updateNamesSize("earth", newVal);
        },
        earthOrbit(newVal) {
            SpacekitService.togglePlanets("orbit", newVal, true);
        },
        earthOrbitColor(newVal) {
            SpacekitService.updateEarthOrbitColor(newVal);
        },
        synodicEarthOrbit(newVal) {
            SpacekitService.togglePlanets("orbit", newVal, true);
        },
        synodicEarthOrbitColor(color) {
            SpacekitService.updateSynodicEarthOrbitColor(color);
        },
        lightEffect(newVal) {
            SpacekitService.toggleLightEffect(newVal);
        },
        guidingArrows(newVal) {
            SpacekitService.toggleGuidingArrows(newVal);
        },
        selectedCloseApproach(newVal) {
            const mainbody = newVal ? newVal.mainBody.id : null;
            SpacekitService.toggleGridSize(mainbody);
        },
        trailDurationYears() {
            SpacekitService.updatePerturbedOrbit();
        },
        leadDurationYears() {
            SpacekitService.updatePerturbedOrbit();
        },
        keplerianOrbitsColor(newVal) {
            SpacekitService.updateKeplerianOrbitsColor(newVal);
        },
        perturbedOrbitsColor(newVal) {
            SpacekitService.updatePerturbedOrbitsColor(newVal);
        },
        planetOrbitLineThicknessValue(newVal){
            SpacekitService.updateOrbitLineThickness(newVal,true);
        },
        objectOrbitLineThicknessValue(newVal){
            SpacekitService.updateOrbitLineThickness(newVal);
        },
        backgroundColor(newVal) {
            SpacekitService.updateBackgroundColor(newVal);
        },
        keepPlanetTexture() {
            SpacekitService.keepPlanetTexture();
        },
        orbitRange(isVisible) {
            if (!this.isSovtTool) {
                return;
            }
            SpacekitService.toggleOrbitRange(isVisible);
        },
        detectionPolar(isVisible) {
            if (!this.isSovtTool) {
                return;
            }
            SpacekitService.toggleDetectionPolar(isVisible);
        },
        detectionPolarTransparency(transparencyValue) {
            if (!this.isSovtTool) {
                return;
            }
            SpacekitService.changeDetectionPolarTransparency(transparencyValue);
        },
        detectionPolarColor(color) {
            if (!this.isSovtTool) {
                return;
            }
            SpacekitService.changeDetectionPolarColor(color);
        },
        limitingMagnitudeLegend(isVisible) {
            if (!this.isSovtTool) {
                return;
            }
            SpacekitService.toggleMagnitudeLimitLabels(isVisible);
        },
        
    },
    methods: {
        onResize() {
            setTimeout(() => {
                const vt = window["vt"];
                const container = vt.getSimulationElement();

                const camera = vt.getViewer().camera;
                camera.aspect = container.clientWidth / container.clientHeight;
                camera.updateProjectionMatrix();
                const cameraOrtho = vt.cameraOrtho;
                cameraOrtho.left = container.clientWidth / -2;
                cameraOrtho.right = container.clientWidth / 2;
                cameraOrtho.top = container.clientHeight / 2;
                cameraOrtho.bottom = container.clientHeight / -2;
                cameraOrtho.updateProjectionMatrix();

                const renderer = vt.getRenderer();
                renderer.setSize(container.clientWidth, container.clientHeight);

                vt.staticForcedUpdate();
            }, 150);
        },
        campos() {
            const vt = window["vt"];
            const zoom = VisualisationService.getZoom();
            const cameraPosition = vt.getViewer().camera.position;
            const cameraTarget = vt.getViewer().cameraControls.target;
            cameraPosition.sub(cameraTarget).setLength(zoom).add(cameraTarget);
        },
        createFakeSunObject() {
            if (this.tool === 'fvt' || this.tool === 'sovt') {
                return;
            }
            const vt = window["vt"];
            const geometry = new THREE.SphereGeometry(0.1, 32, 32);
            const material = new THREE.MeshStandardMaterial({
                color: 0xffffff00,
                side: THREE.BackSide,
                transparent: true,
                opacity: 0,
            });
            const sphere = new THREE.Mesh(geometry, material);
            sphere.name = "sun";
            sphere.renderOrder = 1;
            vt.getScene().add(sphere);
        },
        handleDoubleClick(event) {
            const intersects = UtilsService.getIntersections(event);
            let selectedObjectName = null;
            if (this.tool === "ovt") {
                selectedObjectName = intersects.find((intersected) => intersected.object.name === "sun") ? "sun" : null;
            }
            if (this.tool === "sovt") {
                const sprite = intersects.filter(e => e.object.type === 'Sprite')[0];
                if (sprite && Math.abs(sprite.point.x) <= 0.1 && Math.abs(sprite.point.y) <= 0.1 && Math.abs(sprite.point.z) <= 0.1) {
                    selectedObjectName = "sun";
                }
            }
            !selectedObjectName && (selectedObjectName = UtilsService.getClickedObject(intersects));
            if (selectedObjectName) {
                this.tool === "sovt" && VisualisationService.setSovtFocusedObject(selectedObjectName);
                this.tool === "ovt" && VisualisationService.setOvtFocusedObject(selectedObjectName);
                this.tool === "fvt" && VisualisationService.setFvtFocusedObject(selectedObjectName);

            }
        },
    },
    mounted() {
        if (!this.settings) {
            VisualisationService.setSettingsDefaults();
        }
        SpacekitService.init(this.tool);
        window.addEventListener("resize", this.onResize);

        if (this.tool === "fvt") {
            const vt = window["vt"];
            vt.getViewer().cameraControls.addEventListener("change", this.campos);
        }

        if (this.tool === "sovt" || this.tool === "ovt" || this.tool === 'fvt') {
            this.createFakeSunObject();
            const container = window["vt"].getSimulationElement();
            container.addEventListener("dblclick", this.handleDoubleClick, false);
        }

        setTimeout(() => {
            VisualisationService.reloadTool();
        }, 1000);
    },
    beforeUnmount() {
        const stateObj = { ...MemoryService.getToolState() };
        MemoryService.saveStateOnSwitch(stateObj);
        SpacekitService.destroy();
        window.removeEventListener("resize", this.onResize);
    },
};
</script>

<style></style>
