<template>
    <Tooltip :position="'bottom'" :tooltipText="$keys.tooltips[filterId]">
        <span class="with-filter"
            :class="{'active-filter': filterValue(filterId), 'open-filter': dropboxSetupId === filterId}"
            @click="toggleFiltersSetupId(filterId)">
            {{ displayTitle(filterId) }}<i v-if="filterValue(filterId)" class="icon-filter" />
        </span>
    </Tooltip>
    <Dropbox
        v-if="dropboxSetupId === filterId" 
        :title="displayTitle(filterId)" 
        @close="cancelSetup()" 
        @submit="submitSetup(filterId)" 
        :class="type"
        :config="dropboxConfig()"
        >
        <div class="field">
            <!--button v-if="filterValue(filterId)" class="link" @click="clearSetup(filterId)"-->
            <button v-if="removeFilterLink(filterId)" class="link" @click="clearSetup(filterId)">
                <i class="icon-filter"><i class="icon-x-circle" /></i> <span>{{ $t.removeFilter }}</span>
            </button>
        </div>
        <template v-if="type == 'range'">
            <div class="field min-max">
                <span class="t-uppercase">{{ $t.min }}</span> <input type="number" :value="range[0]" :min="rangeMin" :max="range[1]" @change="rangeInputChange(0, $event)" /> - &nbsp;<span class="t-uppercase">{{ $t.max }}</span> <input type="number" :value="range[1]" :min="range[0]" :max="rangeMax" @change="rangeInputChange(1, $event)" />
            </div>
            <div class="field" />
            <div class="field">
                <Slider :tooltips="false" :step="0.1" v-model="range" :min="rangeMin" :max="rangeMax" />
            </div>
            <div class="range-labels">
                <span v-html="rangeMin" />
                <span v-html="rangeMax" />
            </div>
        </template>
        <template v-else-if="type === 'minValue'">
            <div class="field min-max">
                <span class="t-uppercase">{{ $t.min }}</span> <input type="number" :min="rangeMin" :value="minValue" @change="minValueChange($event)" />
            </div>
        </template>
        <template v-else v-for="(option, index) of options.split(',')" :key="index">
            <Tooltip v-if="filterId === 'database'" :position="'bottom'" :tooltipText="$keys.tooltips[option]">
                <label class="field" :for="`${filterId}_${option}`">
                    <input :type="type" :value="option" :id="`${filterId}_${option}`" :name="filterId" :checked="filterValue(filterId, option)" />{{ displayValue(option) }}
                </label>
            </Tooltip>
            <label v-else class="field" :for="`${filterId}_${option}`">
                <input :type="type" :value="option" :id="`${filterId}_${option}`" :name="filterId" :checked="filterValue(filterId, option)" />{{ displayValue(option) }}         
            </label>
        </template>
        <template v-for="(option, idx) of optionsNA.split(',')" :key="idx">
            <label v-if="!['database', 'bodyType', 'risk', 'pha', 'orbitType'].includes(filterId)" class="field" :for="`${filterId}_${option}`">
                <input type="checkbox" :value="option" :id="`${filterId}_${option}`" :name="filterId" :checked="filterValue(filterId, option)" />{{ displayValue(option) }}
            </label>
        </template>
    </Dropbox>
</template>

<script>
import Dropbox from '@/components/common/Dropbox'
import DropboxService from '@/services/dropbox.service'
import FilteringService from '@/services/filtering.service'
import Config from '@/constants/config'
import Slider from '@vueform/slider'
import Tooltip from '@/components/common/Tooltip'

export default {
    name: 'FilterHandler',
    props: {
        filterId: String,
    },
    components: {
        Dropbox,
        Slider,
        Tooltip,
    },
    data() {
        return {
            filtersObj: {},
            type: Config.filters[this.filterId].type,
            options: Config.filters[this.filterId].options,
            optionsNA: Config.filters['NA'].options,
            rangeMin: 0,
            rangeMax: 1,
            range: [0, 1],
            minValue: 0,
            default: Config.filters[this.filterId].default,
        }
    },
    computed: {
        filters() {
            return FilteringService.getFilters();
        },
        dropboxSetupId() {
            return DropboxService.getDropboxSetupId();
        },
    },
    methods: {
        displayValue(value) {
            let displayValue = value;
            switch (value) {
                case 'true':
                    displayValue = this.$t.yes;
                    break;
                case 'false':
                    displayValue = this.$t.no;
                    break;
                case '0':
                    displayValue = this.$t.none;
                    break;
                case 'JPL':
                    displayValue = this.$t.jplNecs;
                    break;
                default:
                    break;
            }
            return displayValue;
        },
        displayTitle(title) {
            let displayTitle = this.$t[title];
            switch (title) {
                case 'magnitude':
                    displayTitle = this.$t.visualMagnitude;
                    break;
                case 'sunElongation':
                    displayTitle = this.$t.angularDistToSun;
                    break;
                case 'skyMovement':
                    displayTitle = this.$t.apparentVelocity;
                    break;
                case 'galaxyLatitude':
                    displayTitle = this.$t.galacticLatitude;
                    break;
                case 'observationArc':
                    displayTitle = this.$t.lengthObservArc;
                    break;
                case 'moonElongation':
                    displayTitle = this.$t.moonElong;
                    break;
                case 'densityOfStars':
                    displayTitle = this.$t.starDensity;
                    break;
                default:
                    break;
            }
            return displayTitle;
        },
        toggleFiltersSetupId(dropboxSetupId) {
            if (this.dropboxSetupId === dropboxSetupId) {
                DropboxService.setDropboxSetupId('');
            } else {
                DropboxService.setDropboxSetupId(dropboxSetupId);
            }
        },
        cancelSetup() {
            DropboxService.setDropboxSetupId('');
        },
        submitSetup(dropboxSetupId) {
            //var shouldMakeBackendRequest = true;
            const checkedFields = document.querySelectorAll('[name="'+dropboxSetupId+'"]:checked');
            const checkedArray = [];
            for (const checkedField of checkedFields) {
                //checkedArray.push(encodeURIComponent(checkedField.value));
                if (this.type!=='checkbox'){
                    if (checkedField.value === 'N/A' || dropboxSetupId === 'densityOfStars')
                        checkedArray.push(true);
                    else
                        checkedArray.push(false);
                } else {
                    checkedArray.push(checkedField.value);
                }
            }
            if ((!checkedFields||!checkedFields.length) && (this.type !== 'checkbox')){
                checkedArray.push(false);
            }

            if (this.type === 'radio') {
                this.filtersObj[dropboxSetupId] = document.querySelector('[name="'+dropboxSetupId+'"]:checked').value;
            }
            if (this.type === 'range') {
                this.filtersObj[dropboxSetupId] = {
                    [dropboxSetupId+'Min']: this.range[0],
                    [dropboxSetupId+'Max']: this.range[1],
                    [dropboxSetupId+'NA']: checkedArray,
                }
            }
            if (this.type === 'minValue') {
                this.filtersObj[dropboxSetupId] = {
                    [dropboxSetupId+'Min']: this.minValue * 1,
                    [dropboxSetupId+'Max']: null,
                    [dropboxSetupId+'NA']: checkedArray,
                }
            }
            if (this.type === 'checkbox') {
                if (checkedArray.length) {
                    this.filtersObj[dropboxSetupId] = checkedArray;
                } else {
                    delete this.filtersObj[dropboxSetupId];
                    //shouldMakeBackendRequest = false;
                }
            }

            FilteringService.setEmptyFilter(true);
            for (const filter in Config.filters) {
                if ((!this.filtersObj[filter]) && (Config.filters[filter].type === 'checkbox')){
                    FilteringService.setEmptyFilter(false);
                }
            }

            FilteringService.setFilters(this.filtersObj);
            DropboxService.setDropboxSetupId('');
            //FilteringService.runFilteringRequest({}, shouldMakeBackendRequest);
            FilteringService.runFilteringRequest({}, FilteringService.getEmptyFilter());
        },
        clearSetup(dropboxSetupId) { 
            //VisualisationService.setEmptyFilter(true);            
            delete this.filtersObj[dropboxSetupId];   
            if (this.type === 'checkbox') {                      
                this.filtersObj[dropboxSetupId]=Config.filters[dropboxSetupId].options;   
            }
         
            if (this.type === 'range') {
                this.range = this.getRange();
                this.optionsNA = 'N/A';
            }
            if (this.type === 'minValue') {
                this.minValue = this.default; 
                this.optionsNA = 'N/A';               
            }
            FilteringService.setFilters(this.filtersObj);
            DropboxService.setDropboxSetupId('');
            FilteringService.runFilteringRequest();
        },
        filterValue(filterId, option = null) {
            if (this.filters[filterId] && option) {
                // return this.filters[filterId] === encodeURIComponent(option) || this.filters[filterId].includes(encodeURIComponent(option));                
                if (['minValue','range'].includes(this.type) && option === 'N/A') {
                    return this.filters[filterId][filterId + 'NA'] === true || this.filters[filterId][filterId + 'NA'].includes(true);
                } else {
                    return this.filters[filterId] === option || this.filters[filterId].includes(option);
                }
            }
            else if (!this.filters[filterId]){
                if (['minValue','range'].includes(this.type) && option === 'N/A') {
                    return this.optionsNA === 'N/A'; 
                }
            } else {
                return this.filters[filterId];
            }
        },
        removeFilterLink(filterId) {                        
            if (this.type === 'checkbox') {
                if (!this.filters[filterId]) {                        
                    //delete this.filtersObj[filterId];                 
                    return true; // empty check list
                }
                const options = this.filters[filterId].toString();                   
                return options.split(',').length < Config.filters[filterId].options.split(',').length;                   
            }              
            return this.filters[filterId];                                             
    
        },
        getMin() {
            return Config.filters[this.filterId].min ? parseFloat(Config.filters[this.filterId].min) : null;
        },
        getMax() {
            return Config.filters[this.filterId].max ? parseFloat(Config.filters[this.filterId].max) : null;
        },
        getRange() {
            let range = [0, 1];
            if (this.filters && this.filters[this.filterId]) {
                range = [
                    this.filters[this.filterId][this.filterId + 'Min'],
                    this.filters[this.filterId][this.filterId + 'Max'],
                ];
            } else {
                range = [this.rangeMin, this.rangeMax];
            }
            return range;
        },
        dropboxConfig() {
            if (this.dropboxSetupId === 'moonglow') {
                return {
                    class: 'from-right',
                }
            } else {
                return {};
            }
        },
        setDefaults() {
            FilteringService.setEmptyFilter(true); 
            if (this.type === 'range') {
                this.rangeMin = this.getMin();
                this.rangeMax = this.getMax();
                this.range = this.getRange();
            }
            if (this.type === 'minValue') {
                this.rangeMin = this.getMin();
                this.minValue = this.filterValue();
            }
        },
        rangeInputChange(index, event) {
            if (event.target.value === '') {
                if (index) {
                    this.range[index] = this.rangeMax;
                    event.target.value = this.rangeMax;
                } else {
                    this.range[index] = this.rangeMin;
                    event.target.value = this.rangeMin;
                }
            } else {
                this.range[index] = event.target.value;
            }
        },
        minValueChange(event) {
            if (event.target.value === '') {
                event.target.value = this.default;
            } else if (parseFloat(event.target.value) < this.rangeMin) {
                this.minValue = this.rangeMin;
            } else {
                this.minValue = event.target.value;
            }
        }
    },
    mounted() {
        this.filtersObj = this.filters;
        this.setDefaults();
        if (this.type === 'minValue') {
            this.minValue = this.default;
        }
    },
    watch: {
        filters: {
            deep: true,
            handler(newVal) {
                this.filtersObj = newVal;
                if (this.type === 'range') {
                    this.range = this.getRange();
                } else if (this.type === 'minValue' && typeof this.filterValue(this.filterId) !== 'undefined') {
                    this.minValue = this.filterValue(this.filterId)[this.filterId+'Min'];
                }
            }
        }
    }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style>

</style>
