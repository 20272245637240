<template>
    <div class="fvt__objects-configuration" :class="{ hidden: !show }">
        <div class="fvt__objects-configuration__title" :class="{open: !show}" @click="toggleSelectObjectsPanel()">
            <span>{{ $t.searchForCloseApproaches }}</span>
            <Tooltip position="bottom" :tooltipText="$keys.tooltips.closeApproachesSelection">
                <i class="icon-info"/>
            </Tooltip>
        </div>
        <div class="fvt__objects-configuration__title-vertical" :class="{open: !show}" @click="!show ? toggleSelectObjectsPanel() : null">
            {{ $t.searchForCloseApproaches }}
        </div>

        <div
            class="fvt__objects-configuration__close"
            :class="{ open: !show }"
            @click="toggleSelectObjectsPanel()"
        >
            <span class="rotated-text hidden-text">HiddenLabelForLayout</span>
            <span class="rotated-text hidden-text">HiddenLabel</span>
            <span class="rotated-text hidden-text">Hidden</span>
            <span class="rotated-text hidden-text">HiddenLabel</span>
            <span class="rotated-text hidden-text">HiddenLabel</span>
         </div>
        <ul class="fvt__objects-tabs" :class="{ 'fvt__objects-tabs--hidden': !show }">
            <li
                class="fvt__tab"
                :class="{ 'fvt__tab--active': objectsMode === 'database' }"
                @click="changeObjectsMode('database')"
            >
                <span class="fvt__tab-label fvt__tab-label--first">{{ $t.databaseObjects }}</span>
            </li>
            <li
                class="fvt__tab"
                :class="{ 'fvt__tab--active': objectsMode === 'user-defined' }"
                @click="changeObjectsMode('user-defined')"
            >
                <span class="fvt__tab-label">{{ $t.userDefinedObjects }}</span>
            </li>
        </ul>

        <div v-show="objectsMode === 'database'" class="fvt__input-parameters">
            <FlybyMainBodySelector type="database" />
            <FlybyTimeframeSelection />
            <FlybyObjectsSelection />
            <FlybySearch :isBtnDisabled="isSearchCloseApproachDisabled" />
            <p v-if="isNoApproachFoundMsgVisible" class="fvt__error-msg" v-html="`${$t.noCloseApproachesDetected}`"></p>
        </div>
        <div v-show="objectsMode === 'database'" class="fvt__close-approaches">
            <FlybyCloseApproaches />
        </div>
        <FlybyFocusSelector v-show="objectsMode === 'database'" type="database" :selectedType="objectsMode" />

        <div v-show="objectsMode === 'user-defined'" class="fvt__input-parameters">
            <FlybyMainBodySelector type="user-defined" />
            <SyntheticObjects tool="fvt" />
            <FlybySearch mode="user-defined" :isBtnDisabled="isComputeSyntheticObjectDisabled" />
            <p v-if="isFlybyAboveLimit" class="fvt__error-msg">{{ $t.flybyAboveLimit }}</p>
            <p v-if="isSyntheticObjectFlybyCalculated" class="fvt__info">{{ $t.closeApproachCalculated }}</p>
            <FlybyFocusSelector type="user-defined" :selectedType="objectsMode" />
        </div>
    </div>
</template>

<script>
import VisualisationService from "@/services/visualisation.service";
import Tooltip from "@/components/common/Tooltip";
import FlybyTimeframeSelection from "@/components/fvt/FlybyTimeframeSelection";
import FlybyMainBodySelector from "@/components/fvt/FlybyMainBodySelector";
import FlybyObjectsSelection from "@/components/fvt/FlybyObjectsSelection";
import FlybySearch from "@/components/fvt/FlybySearch";
import FlybyCloseApproaches from "@/components/fvt/FlybyCloseApproaches";
import SyntheticObjects from "@/components/common/SyntheticObjects";
import SyntheticObjectsService from "@/services/synthetic-objects.service";
import FlybyFocusSelector from "@/components/fvt/FlybyFocusOnSelector";

export default {
    name: "FlybyObjectsTable",
    components: {
        Tooltip,
        FlybyTimeframeSelection,
        FlybyMainBodySelector,
        FlybyObjectsSelection,
        FlybySearch,
        FlybyCloseApproaches,
        SyntheticObjects,
        FlybyFocusSelector,
    },
    data() {
        return {
            objectsMode: "database",
        };
    },
    computed: {
        show() {
            return VisualisationService.getSelectObjectsShow();
        },
        closeApproaches() {
            return VisualisationService.getCloseApproaches();
        },
        selectedCloseApproach() {
            return VisualisationService.getSelectedCloseApproach();
        },
        focused() {
            return VisualisationService.getOrbitViewerFocusedOnObject();
        },
        isComputeSyntheticObjectDisabled() {
            if (!this.isSyntheticObjectAdded) {
                return true;
            }
            const mainBody = SyntheticObjectsService.getSyntheticMainBody();
            if (!mainBody) {
                return true;
            }
            return (
                this.isSyntheticObjectFlybyCalculated &&
                VisualisationService.getSelectedCloseApproach().mainBody.id ===
                    SyntheticObjectsService.getSyntheticMainBody().id
            );
        },
        isSearchCloseApproachDisabled() {
            const mainBody = VisualisationService.getMainBody();
            return !mainBody.length;
        },
        isSyntheticObjectAdded() {
            return SyntheticObjectsService.getSyntheticObjectsList().length;
        },
        isNoApproachFoundMsgVisible() {
            return VisualisationService.getIsNoApproachFoundMsgVisible();
        },
        isFlybyAboveLimit() {
            return SyntheticObjectsService.getIsSyntheticObjectFlybyAboveLdLimit();
        },
        isSyntheticObjectFlybyCalculated() {
            return SyntheticObjectsService.checkIsSyntheticObjectFlybyCalculated();
        },
        isNonSyntheticObjectFlybyCalculated() {
            return SyntheticObjectsService.checkIsNonSyntheticObjectFlybyCalculated();
        },
    },
    methods: {
        toggleSelectObjectsPanel() {
            VisualisationService.setSelectObjectsShow(!this.show);
        },
        focusOnObject() {
            VisualisationService.focusOnSelectedObject(true);
        },
        focusOnMainBody() {
            VisualisationService.focusOnCenter(true);
        },
        changeObjectsMode(modeName) {
            this.objectsMode = modeName;
        },
    },
};
</script>

<style></style>
