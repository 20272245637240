export default {
    setMultiselectOptions: 'common/setMultiselectOptions',
    setOptRoute: 'common/setOptRoute',
    setScdRoute: 'common/setScdRoute',
    setPreviousTimeOVT: 'common/setPreviousTimeOVT',
    setPreviousTimeFVT: 'common/setPreviousTimeFVT',
    setPlaybackSpeedFVT: 'common/setPlaybackSpeedFVT',
    setPlaybackSpeed: 'common/setPlaybackSpeed',
    setStartDate: 'common/setStartDate',
    setEndDate: 'common/setEndDate',
    setEmptyFilter: 'common/setEmptyFilter',
    setCurrentDate: 'common/setCurrentDate',
    setNavigationVisible: 'common/setNavigationVisible',
    setTool: 'common/setTool',
    setToolTitle: 'common/setToolTitle',
    setLoadingObservatoryList: 'common/setLoadingObservatoryList',
    setObservatoryList: 'common/setObservatoryList',
    setObservatoryElevation: 'common/setObservatoryElevation',
    setSelectedObservatory: 'common/setSelectedObservatory',
    setTimeFrame: 'common/setTimeFrame',
    setTimestep: 'common/setTimestep',
    setTimestepMemory: 'common/setTimestepMemory',
    setSpinnerShow: 'common/setSpinnerShow',
    setRequestCancel: 'common/setRequestCancel',
    setPopupShow: 'common/setPopupShow',
    setObjects: 'common/setObjects',
    setObjectsSelected: 'common/setObjectsSelected',
    setObjectsInitialLoadPerformed: 'common/setObjectsInitialLoadPerformed',
    setFilters: 'common/setFilters',
    setDropboxSetupId: 'common/setDropboxSetupId',
    setLoadingAsteroidsList: 'common/setLoadingAsteroidsList',
    setAvailableAsteroidsList: 'common/setAvailableAsteroidsList',
    setFinalAsteroidsList: 'common/setFinalAsteroidsList',
    setSyntheticDefaultObjectsList: 'common/setSyntheticDefaultObjectsList',
    setSyntheticFvtObjectsList: 'common/setSyntheticFvtObjectsList',
    setInsertAsteroidsList: 'common/setInsertAsteroidsList',
    setAsteroidsNames: 'common/setAsteroidsNames',
    setConfigurationPopupMode: 'common/setConfigurationPopupMode',
    setPaginationFrom: 'common/setPaginationFrom',
    setPaginationLoading: 'common/setPaginationLoading',
    setToken: 'common/setToken',
    setTokenCancelled: 'common/setTokenCancelled',
    setUserDefinedObjects: 'common/setUserDefinedObjects',
    setSetupMemory: 'common/setSetupMemory',
    setToolState: 'common/setToolState',
    setEphemerides: 'common/setEphemerides',
    setEphemeridesFiltered: 'common/setEphemeridesFiltered',
    setEphemeridesShortterm: 'common/setEphemeridesShortterm',
    setEphemeridesActiveRow: 'common/setEphemeridesActiveRow',
    setEphemeridesColumns: 'common/setEphemeridesColumns',
    setAvailableEphemeridesColumns: 'common/setAvailableEphemeridesColumns',
    setFinalEphemeridesColumns: 'common/setFinalEphemeridesColumns',
    setEphemeridesOnlyObservable: 'common/setEphemeridesOnlyObservable',
    setShorttermDate: 'common/setShorttermDate',
    setCalculationProgress: 'common/setCalculationProgress',
    setCalculationEstimatedTime: 'common/setCalculationEstimatedTime',
    setCalculatedObjects: 'common/setCalculatedObjects',
    setLongtermObjectName: 'common/setLongtermObjectName',
    setLongtermActivePlots: 'common/setLongtermActivePlots',
    setLongtermObservablePlots: 'common/setLongtermObservablePlots',
    setLongtermEphemerides: 'common/setLongtermEphemerides',
    setLongtermVisualiseOnChart: 'common/setLongtermVisualiseOnChart',
    setLastCalculatedEphemerides: 'common/setLastCalculatedEphemerides',
    setLongtermCurrentPlot: 'common/setLongtermCurrentPlot',
    setEnableOptFiltering: 'common/setEnableOptFiltering',
    setEnableOptResults: 'common/setEnableOptResults',
    setTimezone: 'common/setTimezone',
    setSkychartEnableVisualisation: 'common/setSkychartEnableVisualisation',
    setSkychartObjectsShow: 'common/setSkychartObjectsShow',
    setSkychartMagNumber: 'common/setSkychartMagNumber',
    setOrbitVisualisationObjectsShow: 'common/setOrbitVisualisationObjectsShow',
    setSkychartActiveObjectName: 'common/setSkychartActiveObjectName',
    setSkychartSettingsPanelName: 'common/setSkychartSettingsPanelName',
    setSkychartSettings: 'common/setSkychartSettings',
    setSkychartLoadedObjects: 'common/setSkychartLoadedObjects',
    setSkychartExceedPathAngleObjects: 'common/setSkychartExceedPathAngleObjects', 
    setSkychartTraceObjects: 'common/setSkychartTraceObjects',
    setSkychartTraceDate: 'common/setSkychartTraceDate',
    setSkychartObjectTracking: 'common/setSkychartObjectTracking',    
    setSkychartDmsFormat: 'common/setSkychartDmsFormat',
    setSkychartObjectsDetailsHide: 'common/setSkychartObjectsDetailsHide',
    setIsChecked: 'common/setIsChecked',
    setIsPerturbed: 'common/setIsPerturbed',
    setSelectedObjectName: 'common/setSelectedObjectName',
    setLoadingObjectsEphemerides: 'common/setLoadingObjectsEphemerides',
    setRiskListChecked: 'common/setRiskListChecked',
    setOrbitVisualisationActiveSelects: 'common/setOrbitVisualisationActiveSelects',
    setOrbitVisualisationNow: 'common/setOrbitVisualisationNow',
    setSimulationTime: 'common/setSimulationTime',
    setOrbitSettings: 'common/setOrbitSettings',
    setOrbitSettingsOVT: 'common/setOrbitSettingsOVT',
    setOrbitSettingsFVT: 'common/setOrbitSettingsFVT',
    setOrbitSettingsPanelName: 'common/setOrbitSettingsPanelName',
    setKeplerianData: 'common/setKeplerianData',
    setPerturbedData: 'common/setPerturbedData',
    setSynodicPerturbedData: 'common/setSynodicPerturbedData',
    setOrbitActiveObject: 'common/setOrbitActiveObject',
    setOrbitViewerFocusedOnObject: 'common/setOrbitViewerFocusedOnObject',
    setOrbitRecorderShow: 'common/setOrbitRecorderShow',
    setOrbitRecorderOnAir: 'common/setOrbitRecorderOnAir',
    setMainBody: 'common/setMainBody',
    setSyntheticMainBody: 'common/setSyntheticMainBody',
    setCloseApproaches: 'common/setCloseApproaches',
    setCloseApproachesRequest: 'common/setCloseApproachesRequest',
    setSelectedCloseApproach: 'common/setSelectedCloseApproach',
    setCloseApproachDetected: 'common/setCloseApproachDetected',
    setIsNoApproachFoundMsgVisible: 'common/setIsNoApproachFoundMsgVisible',
    setIsSyntheticObjectFlybyAboveLdLimit: 'common/setIsSyntheticObjectFlybyAboveLdLimit',
    setRestoreFlybyTime: 'common/setRestoreFlybyTime',
    setFlybyTimeframe: 'common/setFlybyTimeframe',
    setFlybyClosestPoint: 'common/setFlybyClosestPoint',
    setMinCameraDistance: 'common/setMinCameraDistance',
    setZoom: 'common/setZoom',
    setSynodicOrbitChartSettings: 'common/setSynodicOrbitChartSettings',
    setSynodicOrbitChartsData: 'common/setSynodicOrbitChartsData',
    setSelectedSynodicObjectName: 'common/setSelectedSynodicObjectName',
    setCalculatedRealSynodicObjectsList: 'common/setCalculatedRealSynodicObjectsList',
    setVisibleRealSynodicObjectsList: 'common/setVisibleRealSynodicObjectsList',
    setSovtFocusedObject: 'common/setSovtFocusedObject',
    setOvtFocusedObject: 'common/setOvtFocusedObject',
    setFvtFocusedObject: 'common/setFvtFocusedObject',
    setPerturbedSynodicOrbitList: 'common/setPerturbedSynodicOrbitList',
    setSynodicActiveObject: 'common/setSynodicActiveObject',
    setDetectionPolarPoints: 'common/setDetectionPolarPoints',
    setSynodicSpeed: 'common/setSynodicSpeed',
    setZoomOVT: 'common/setZoomOVT',
    setZoomFVT: 'common/setZoomFVT',
    setImpactorsList: 'common/setImpactorsList',
    set_showNEOs: 'common/set_showNEOs',
    set_showNonNEOs: 'common/set_showNonNEOs',
    set_showNEOCP: 'common/set_showNEOCP',
    set_showAsteroids: 'common/set_showAsteroids',
    set_showComets: 'common/set_showComets'
}
