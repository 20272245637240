<template>
    <div class="object-configuration">
        <ul class="object-configuration__tabs" :style="ulStyle">
            <li class="object-configuration__tab" :class="{active: configurationPopupMode === 'select-list'}" @click="changeMode('select-list')" :style="firstLiStyle">
                <span class="object-configuration__tab--inner" :style="spanStyle">
                    {{ $t.selectObjectsFromList }}
                    <Tooltip position="top" :tooltipText="$t.objectsColorInList" :style="tooltipStyle" >
                        <i class="icon-info" :style="iconStyle" />
                    </Tooltip>
                </span>
                <div :style="borderStyle"></div>
             </li>
            <li class="object-configuration__tab" :class="{active: configurationPopupMode === 'insert-list'}" @click="changeMode('insert-list')" :style="liStyle">
                <span class="object-configuration__tab--inner" :style="spanStyle">{{ $t.insertList }}</span>
            </li>
        </ul>
        <div class="popup-content">
            <div v-show="configurationPopupMode === 'select-list'" class="select-list">
                <div class="select-list__column">
                    <span class="label">
                        <i class="icon-search" />
                        <input 
                            class="select-list__autocomplete"
                            ref="objectInput"
                            type="text"
                            v-model="name"
                            :placeholder="$t.startTyping"
                            :class="{loading: loadingAsteroidsList}"
                            @keyup="findAsteroidByPhrase($event)"
                            />
                    </span>
                    <ul class="select-list__objects available">
                        <li class="asteroid available" :class="{active: isAvailableSelected(item)}" :style="{ color: getItemColor(item) }" v-for="(item, index) of availableAsteroidsList" :key="index" @click="onAvailableSelect(item)">
                            {{ displayName(item) }}
                        </li>
                    </ul>
                    <div class="grid-container">
                        <div v-for="(property, key) in  objectProperties" :key="key" class="switcher-container">   
                            <!-- Render empty div if key is null -->
                            <div v-if="key === 'null'"></div>

                            <!-- Render the normal content if key is not null -->
                            <div v-else class="switcher__wrapper">
                                <input
                                    class="toggle-switch"
                                    :id="key"
                                    type="checkbox"
                                    :checked="isChecked(property)"
                                    @change="onChange(property, $event.target.checked)"
                                />
                                <label class="toggle-switch__button" :for="key"></label>
                                <span class="switcher__label">{{ this.$t[property] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="select-list__column">
                    <span class="label"></span>
                    <div class="buttons">
                        <div>
                            <button class="secondary" :class="{disabled: !availableSelected.length}" @click="add()"><span>{{ $t.add }}</span><i class="icon-chevron-right" /></button>
                            <button class="secondary" :class="{disabled: !availableAsteroidsList.length}" @click="addAll()"><span>{{ $t.addAll }}</span><i class="icon-chevrons-right" /></button>
                        </div>
                        <div>
                            <button class="secondary" :class="{disabled: !finalSelected.length}" @click="remove()"><i class="icon-chevron-left" /><span>{{ $t.remove }}</span></button>
                            <button class="secondary" :class="{disabled: !finalAsteroidsList.length}" @click="removeAll()"><i class="icon-chevrons-left" /><span>{{ $t.removeAll }}</span></button>
                        </div>
                    </diV>
                </div>
                <div class="select-list__column">
                    <span class="label">
                        {{ $t.finalList }} ({{ finalAsteroidsList.length }} {{ $t.objectsSelected }}):
                    </span>
                    <ul class="select-list__objects final">
                        <li class="asteroid final" :class="{active: isFinalSelected(item)}" :style="{ color: getItemColor(item) }" v-for="(item, index) of finalAsteroidsList" :key="index" @click="onFinalSelect(item)">
                            {{ displayName(item) }}
                        </li>
                    </ul>
                </div>
            </div>
            <div v-show="configurationPopupMode === 'insert-list'" class="insert-list">
                <span class="label">
                    {{ $t.pleaseProvideObjectDesignatorsOrObjectNames }}
                </span>
                <span class="label">
                    {{ $t.commaSeparatedValuesOrNextLineValues }}:
                </span>
                <textarea :value="insertAsteroidsList" class="comma-separated-list" @change="updateInsertList($event)"/>
            </div>
        </div>
        <div class="popup-footer">
            <button class="cancel" @click="cancel()">{{ $t.cancel }}</button>
            <button class="primary" @click="submit()">{{ $t.save }}</button>
        </div>
    </div>
</template>

<script>
import ObjectsService from '@/services/objects.service'
import VisualisationService from '@/services/visualisation.service'
import Tooltip from '@/components/common/Tooltip'
import config from '@/constants/config'

export default {
    name: 'PopupObjectsConfiguration',
    props: {
        config: {}
    },
    components: {
        Tooltip
    },
    data() {
        return {
            name: '',
            typingDelay: null,
            availableSelected: [],
            finalSelected: [],
            ulStyle: {
                // 'flex' will evenly space the 'li' elements
                display: 'flex',
                justifyContent: 'space-between',
                textAlign: 'center',
                borderBottom: '1px solid $bg-lightest'
            },
            liStyle: {
                // 'li' elements will take up to 50% of the width and centers their contents using flex
                width: '50%',
                padding: '15px 0',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            },
            firstLiStyle: {
                // inherits from 'listyle' but does not include border-right allowing 'borderStyle' div to be added separately
                width: '50%',
                padding: '15px 0',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            },
            // adds an absolutely positioned div to simulate a border with a height of 16px.
            borderStyle: {
                position: 'absolute',
                right: '0',
                height: '16px',
                width: '1px',
                backgroundColor: '#3D4659',
                top: '50%',
                transform: 'translateY(-50%)'
            },
            // uses inline-flex and whiteSpace: nowrap to ensure contents stay on one line.
            spanStyle: {
                display: 'inline-flex',
                alignItems: 'center',
                whiteSpace: 'nowrap'
            },
            // ensures the icon has a transparent background with backgroundColor: 'transparent'.
            iconStyle: {
                color: '#0F84DD',
                cursor: 'pointer',
                lineHeight: 'inherit',
                verticalAlign: 'middle',
                backgroundColor: 'transparent'
            },
            tooltipStyle: {
                whiteSpace: 'normal',  // Allow text to wrap
                color: '#fff', 
                padding: '5px 5px',
                borderRadius: '4px', 
                maxWidth: '200px' 
            },
            objectProperties: {
                'NEO': 'showNEOs', 
                'non-NEO': 'showNonNEOs', 
                'NEOCP':'showNEOCP',
                'null':null,
                'Asteroid':'showAsteroids',
                'Comet':'showComets',
            },
        }
    },
    computed: {
        configurationPopupMode() {
            return ObjectsService.getConfigurationPopupMode();
        },
        insertAsteroidsList() {
            return ObjectsService.getInsertAsteroidsList();
        },
        finalAsteroidsList() {
            return ObjectsService.getFinalAsteroidsList();
        },
        availableAsteroidsList() {
            return ObjectsService.getAvailableAsteroidsList();
        },
        loadingAsteroidsList() {
            return ObjectsService.getLoadingAsteroidsList();
        },
    },
    methods: {
        getItemColor(item) {
            const settings = config.visualisation.settings;
            let color = settings.NEAcolor;
            const inv = (hex) => '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('');
                      
            if (settings && item.code == "non-NEO") {  
                color = settings.nonNEAcolor;
            } else if (settings && item.sourceType.name == "NEOCP"){
                color = settings.NEOCPcolor;
            }

            if (!this.isFinalSelected(item) && !this.isAvailableSelected(item) || item.sourceType.name == "NEOCP"){
                return color;
            }
            else{
                return inv(color);
            }
        },
        displayName(item) {
            return ObjectsService.objectNumberAndName(item);
        },
        updateInsertList($event) {
            const insertList = $event.target.value.replace(/ /g, '').replace(/\n/g, ',');
            const insertListArray = insertList.split(',').filter(e => e !== '');
            const insertListConverted = insertListArray.join(',')
            ObjectsService.setInsertAsteroidsList(insertListConverted);
        },
        findAsteroidByPhrase($event) {
            this.availableSelected = [];
            clearTimeout(this.typingDelay);
            this.typingDelay = setTimeout(function() {
                const phrase = $event.target.value;
                ObjectsService.findAsteroidByPhrase(phrase);
            }, 400);
        },
        onAvailableSelect(item) {
            if (this.isAvailableSelected(item)) {
                this.availableSelected = this.availableSelected.filter(e => e.name !== item.name);
            } else {
                this.availableSelected.push(item);
            }
        },
        isAvailableSelected(item) {
            return this.availableSelected.filter(e => e.name === item.name).length;
        },
        onFinalSelect(item) {
            if (this.isFinalSelected(item)) {
                this.finalSelected = this.finalSelected.filter(e => e.name !== item.name);
            } else {
                this.finalSelected.push(item);
            }
        },
        isFinalSelected(item) { 
            return this.finalSelected.filter(e => e.name === item.name).length;
        },
        add() {
            this.availableSelected = ObjectsService.objectConfigurationAdd(this.availableSelected);
        },
        addAll() {
            this.availableSelected = ObjectsService.objectConfigurationAddAll();
        },
        remove() {
            this.finalSelected = ObjectsService.objectConfigurationRemove(this.finalSelected);
        },
        removeAll() {
            this.finalSelected = ObjectsService.objectConfigurationRemoveAll();
        },
        changeMode(mode) {
             ObjectsService.setConfigurationPopupMode(mode);
        },
        cancel() {
            this.$emit('cancel');
        },
        async submit() {
            if (this.configurationPopupMode === 'insert-list') {
                await ObjectsService.findAsteroidByName();
            }
            ObjectsService.updateAsteroidsList();
            this.$emit('submit');
        },
        isChecked(property){              
            return VisualisationService.getProperty(property);      
        },     
        onChange(property, isChecked) {
            VisualisationService.setProperty(property, isChecked);
            ObjectsService.findAsteroidByPhrase(this.$refs.objectInput.value);
        },
    },
    mounted() {
        this.$refs.objectInput.focus();
    }
}
</script>

<style lang="scss">
@import "@/styles/opt/popup-objects-configuration.scss";

</style>
